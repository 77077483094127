@import 'css/base.scss';

// For textbox with prepend/append
.input-group.colour {
    .input-group-prepend {
        .input-group-text {
            height: inherit;
            width: pxToRem(14);
            box-sizing: content-box;
        }
    }

    .color-picker {
        position: absolute;
        left: 0;
        top: 3rem;
        z-index: 9;

        // Hide RGB input
        > .sketch-picker { padding-bottom: 6px !important;
            > .flexbox-fix:nth-child(3) { display: none !important; }
        }
    }
}
