@import 'css/base.scss';

.new-promotion-item {   
    h2 {
         margin-top: 1.5rem; 

         //Needed for icons to align-properly when passed into the header
         & .icon-text-container { line-height: 1.5 !important; } 
         @include text-large1;
    }
    
    .btn { margin-top: pxToRem(24); }

    .padded-container{ margin-bottom: 1.5rem; }
}
