@import 'css/base.scss';

.page-section {
    display: flow-root;  // Only needed because PaginationControl is float
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px $color-light-20 solid;

    /* Fixes display for IE as it does not support flow-root. Optimally float and flow-root should be replaced with flexbox */
    &:after{
        content: "";
        display: block;
        clear: both;
    }

    &:first-of-type { margin-top: 0; }

    &:last-of-type, &.no-divider {
        padding-bottom: 0; border-bottom: 0;
    }

    &.no-margin-top { margin-top: 0; }

    &.no-padding-bottom { padding-bottom: 0; }

    > h1, h2 { &:last-child { margin-bottom: 0 }}

    @include media-breakpoint-up(md) {
        &:not(.full-width) { max-width: 1094px; }
    }

    @include media-breakpoint-down(sm) {
        & {
            margin-left: auto;
            margin-right: auto;
            max-width: 600px;
        }
    }
}
