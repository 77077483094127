@import 'css/base.scss';

$tooltip-bg: $color-light;
$shadow-blur: pxToRem(6);
//$shadow-color: $color-shadow-2;
$shadow-color: rgba(0,0,0,0.5);

.__react_component_tooltip.type-dark {
    pointer-events: auto;
    padding: 1rem 1.5rem;
    max-width: 96%;
    box-shadow: 0 0 $shadow-blur 0 $shadow-color, inset 0 0 1px 1px rgba(242, 242, 242, 0.5);
    background: $tooltip-bg; color: $color-text;
    font-size: 1rem;
    opacity: 1;
    &.show {
        opacity: 1;
    }

    &.place-top, &.place-right, &.place-bottom, &.place-left {
        &:after { width: 1rem; height: 1rem; border: none; background: $tooltip-bg;
                  box-shadow: calc($shadow-blur / 2) calc($shadow-blur / 2) $shadow-blur calc($shadow-blur / -2) $shadow-color; }
    }
    &.place-left:after { transform: translateY(-25%) rotate(-45deg); }
    &.place-top:after { transform: rotate(45deg); }
    &.place-right:after { transform: translateY(-25%) rotate(135deg); }
    &.place-bottom:after { transform: rotate(-135deg); }


    .tooltip-content { max-width: pxToRem(550); }  // Note this cannot be applied to content coming from data-tip

    table th { vertical-align: top; }  // the only table in tooltip is currently in CVN tooltip
}
