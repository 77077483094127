@import '~css/base.scss';

.form-header {
    margin-bottom: 2rem;

    .form-title-header {
        margin-bottom: 0.25em;
        font-size: pxToRem(20);
    }

    .form-title-description {
        margin-top: 0;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 3rem;

        .form-title-header {
            font-size: pxToRem(32);
            font-weight: normal;
        }
        
        .form-title-description {
            font-size: pxToRem(24);
        }
    }
}
