@import '~css/base.scss';

// general ---
.invoice-settings-item-container {
    display: flex;
    flex-direction: row;
    padding-top: pxToRem(8);

    @include media-breakpoint-down(xs) {
        width: 100%;
    }
}

.invoice-settings-item-heading {
    margin-right: pxToRem(12);
    margin-bottom: 0;
}

.invoice-settings-item-subtitle {
    margin-top: pxToRem(16);
    margin-bottom: 0;
    overflow-wrap: break-word;
}

.invoice-settings-item-loading-indicator {
    margin-top: pxToRem(14);
    width: pxToRem(24);
    height: pxToRem(24);
    display: flex;
    align-items: flex-start;
}

.invoice-settings-tooltip-icon-container {  
    @include media-breakpoint-down(xs) {
        margin-left: auto;
    }
}

.invoice-settings-tooltip-icon {
    height: pxToRem(20);
    width: pxToRem(20);
}

.invoice-settings-input-field {
    max-width: pxToRem(460);
    margin-top: pxToRem(13);
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
        max-width: 100%;
    }
}

.invoice-settings-input-container {
    display: flex;
    flex-direction: row;
    margin-bottom: pxToRem(24);
    margin-top: 0;

    @include media-breakpoint-down(xs) {
        flex-direction: column;
        height: auto;
    }
}

.invoice-settings-add-button {
    margin-top: pxToRem(32) !important;
    min-width: pxToRem(156) !important;
    width: pxToRem(156);

    @include media-breakpoint-down(xs) {
        margin-top: 0 !important;
    }
}

.invoice-settings-list {
    margin-bottom: 0 !important;
}

.invoices-error-message {
    margin-top: pxToRem(15);
    margin-bottom: pxToRem(14);
    color: $color-utility-red;
}
