@import "css/constants/colors.scss";
@import "css/constants/mixins.scss";
@import "css/constants/layout.scss";

img { max-width: 100%; }

.group:after {
    content: "";
    display: table;
    clear: both;
}

//#region ---------- Form ----------

// Textbox + Textarea + Select
.form-control, input.form-control, textarea.form-control { border-radius: $input-border-radius; }

// Error message (for all field/control types)
.form-group, .form-check, .selector-group {
    > .error {
        @include fieldErrorText();
    }
}

// For textbox with prepend/append
.input-group {
    .input-group-prepend, .input-group-append {
        .input-group-text {
            border-radius: 0; background: $color-light;
            padding-left: $input-padding-x; padding-right: $input-padding-x;
        }
    }

    .input-group-text {
        border-radius: 0; background: $color-light;
        padding-left: $input-padding-x; padding-right: $input-padding-x;
    }

    @mixin highlight($color: $color-focus) {  // to highlight the prepend box too
        .input-group-prepend {
            margin-right: pxToRem(-2);
            .input-group-text {
                padding-right: $input-padding-x + pxToRem(1);
            }
        }
        .input-group-prepend, .input-group-append {
            .input-group-text {
                @include inputHighlight($color);
            }
        }
        input { @include inputHighlight($color); }
    }

    &:hover, &.focused { @include highlight(); }
    &.has-error { @include highlight($color-error); }
}

//#endregion

.clear {
    clear: both;
}