@import 'css/base.scss';

$side-padding: pxToRem(64);

.modal-dialog { 
    .modal-content { display: block; position: relative;

        .modal-header { border: none; padding: pxToRem(56) $side-padding pxToRem(16);
            &:last-child { padding-bottom: 3rem; }
            
            .modal-title { width: 100%; text-align: center; @include text-large1(); font-weight: bold;
                .icon { display: block; margin: 0 auto pxToRem(10); width: 3rem; height: 3rem; }
            }
            button.close { position: absolute; top: pxToRem(32) - 0.6rem; right: pxToRem(24); font-size: 2.2rem; color: $color-text; }
        }

        .modal-body { padding: 0 $side-padding 1.5rem; 
            &:last-child { padding-bottom: 3rem; }

            > :last-child { margin-bottom: 0; }
        }

        .modal-footer { border: none; margin: 0; padding: 0 $side-padding pxToRem(56); flex-flow: row wrap; justify-content: flex-start; 
            > * { margin-left: 0; } // Undo bootstrap style

            .button-container { 
                @include justifyButtons; 
            }

            .divider { margin: 0 0 1.5rem;
                &.none { height: 0; background-color: transparent; }
            }
        }
    }

    @include media-breakpoint-down(xs) {
        $side-padding: pxToRem(24);

        .modal-content {
            .modal-header { padding: pxToRem(32) $side-padding pxToRem(16);
                //.modal-title { @include text-large3-emphasis; }
                button.close { top: pxToRem(12) - 0.6rem; right: pxToRem(12); }
            }
            .modal-body { padding: 0 $side-padding pxToRem(28); }
            .modal-footer { padding: 0 $side-padding pxToRem(32); justify-content: center; }
        }
    }

    @include media-breakpoint-up(sm) {
        width: 600px !important;
        max-width: 95% !important;
    }
}
