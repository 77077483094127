@import '~css/base.scss';
@import './InvoiceSettings.scss';

.invoice-tax-name-input {
    margin-right: pxToRem(16);
    @include media-breakpoint-down(xs) {
        margin-right: 0;
    }
}

.invoice-tax-rate-input {
    margin-right: pxToRem(32);
    @include media-breakpoint-down(xs) {
        margin-right: 0;
    }
}

.invoice-toggle-container {
    display: flex;
    flex-direction: row;
    height: pxToRem(32);
    margin-top: pxToRem(24);

    @include media-breakpoint-down(xs) {
        margin-top: pxToRem(32);
    }
}

.invoice-toggle {
    width: pxToRem(56);
    margin-right: pxToRem(8);
}

.invoice-toggle-title {
    align-self: center;
}