@import './Input.scss';
@import 'css/constants/colors.scss';

.input-group-currency {
    flex-wrap: nowrap !important;
}

.input-group > .textbox {
    width: 80%;
}

.input-group-text {
    @include textbox();
}

.input-group.currency {
    .input-group-text {
        font-weight: bold; background-color: white;
    }
}
