@import 'css/base.scss';

@mixin accordion {
    .accordion-title { cursor: default;
        &:after { 
            @include pseudo(inline-block, static);
            margin-left: pxToRem(16); width: pxToRem(16); height: pxToRem(16); 
            background: url('~images/ArrowDown.svg') no-repeat center;
            @include rotate(0);
            transition: .15s;
        }
    }

    &.expanded {
        .accordion-title:after { @include rotate(-180); }
        .accordion-content { max-height: 640; } // Increase if necessary, but only reduce (overwrite) at the specific element is too big
    }

    &:not(.expanded) .accordion-content { padding: 0; max-height: 0; overflow: hidden; }
}

.accordion { margin-bottom: pxToRem(16);

    .accordion-title { margin-bottom: pxToRem(8); @include text-large3-emphasis; }
    .accordion-content { padding: pxToRem(8) 0; 
        > *:last-child { margin-bottom: 0; }
    }

    &:not(.enable-up-xs):not(.enable-up-sm):not(.enable-up-md):not(.enable-up-lg):not(.enable-up-xl):not(.enable-down-xs):not(.enable-down-sm):not(.enable-down-md):not(.enable-down-lg):not(.enable-down-xl) {
        //each :not acts like an && case
        @include accordion; 
    }

    &.enable-up-xs { @include media-breakpoint-up(xs) { @include accordion; } }
    &.enable-up-sm { @include media-breakpoint-up(sm) { @include accordion; } }
    &.enable-up-md { @include media-breakpoint-up(md) { @include accordion; } }
    &.enable-up-lg { @include media-breakpoint-up(lg) { @include accordion; } }
    &.enable-up-xl { @include media-breakpoint-up(xl) { @include accordion; } }
    &.enable-down-xs { @include media-breakpoint-down(xs) { @include accordion; } }
    &.enable-down-sm { @include media-breakpoint-down(sm) { @include accordion; } }
    &.enable-down-md { @include media-breakpoint-down(md) { @include accordion; } }
    &.enable-down-lg { @include media-breakpoint-down(lg) { @include accordion; } }
    &.enable-down-xl { @include media-breakpoint-down(xl) { @include accordion; } }
}

