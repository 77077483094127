@import 'css/base.scss';

.request-template {
    color: #000;

    &:link,
    &:visited,
    &:active,
    &:hover {
        color: #000;
        text-decoration: none !important;
    }

    &:hover > .padded-container {
        @include cardDropShadow();
    }
}
