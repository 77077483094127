@import 'css/base.scss';

.payment-input-selector { margin-bottom: 1.5rem;

    h2 { margin-bottom: 1.5rem; }

    table {
        @include tableDefault();
        margin-bottom: 0;
        width: 100%;

        th { font-weight: bold; }

        td.radio { width: pxToRem(48); }

        .card-logo { height: 1.1em; }
    }
    
    .new-token-container {
        &:not(.standalone) { 
            @include media-breakpoint-down(xs) { padding: 0 1rem 0.5rem; }
            @include media-breakpoint-up(sm) { padding: 0 1.5rem 0.5rem pxToRem(60); }
        }

        &.grey { background-color: $color-light-10; }
    }
    
}