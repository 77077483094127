@import 'css/base.scss';

$item-v-spacing: pxToRem(8) !default;
$item-v-spacing-sm: pxToRem(16) !default;
$dt-width: 50% !default;

@mixin grid() {
    //#region --- IE fix ---
    display: -ms-grid;
    -ms-grid-columns: minmax(min-content, max-content) minmax(50%, 1fr);

    > *:nth-child(even) { -ms-grid-column: 2 };

    @for $i from 1 through 20 {  // Increase the range if necessary
        > dt:nth-of-type(#{$i}), > dd:nth-of-type(#{$i}) { -ms-grid-row: $i; }
    }
    //#endregion

    display: grid;
    grid-template-columns: minmax(min-content, max-content) minmax(50%, 1fr);

    > dt { margin-right: 2rem; }
}

.description-list {
    &:not(.two-column) {
        @include grid();

        dt, dd {
            &:last-of-type {
                margin-top: 0 !important;
                margin-bottom: 0;
            }
        }
    }

    dt, dd { margin: 0 0 $item-v-spacing; }
    dd { word-break: break-all; }
    dd.no-word-break { word-break: initial; }

    &.space-between {
        dd { text-align: right; }
    }

    &.two-column {
        > div { display: flex;
            dt { flex-shrink: 0; width: auto; margin-right: 1rem; max-width: 60%; }
        }
    }

    &:not(.inline-xs) {
        @include media-breakpoint-down(xs) {  // Stack on XS
            &:not(.two-column) { display: block; }
            &.two-column > div { display: block; }

            dt, dd { margin: 0; width: 100%; }
            dd { margin: 0 0 $item-v-spacing-sm; }
            &.space-between dd { text-align: left; }
        }
    }

    &.striped {
        margin-bottom: 2rem;
        dt, dd {
            margin: 0;
            padding: 1rem;
            background: $color-light-10;
            &:nth-child(n+3) { background: $color-light; }
        }
    }

    &.has-divider { margin-bottom: -0.5rem; }  // to cancel out the margin from the last dt/dd

    &.grey-label {
        dt {
            font-weight: normal;
            color: $color-dark-65;
        }
    }

    &.two-column {
        margin: 0;
        @include media-breakpoint-up(lg) { column-count: 2; }
    }

    &.label-no-break-line {
        dt {
            white-space: nowrap;
        }
    }
}

// When inside modal, this list needs to be more compact
$modal-item-v-spacing: pxToRem(4) !default;
$modal-item-v-spacing-xs: pxToRem(8) !default;

.modal .description-list {
    dt, dd { margin-bottom: $modal-item-v-spacing; }

    &:not(.inline-xs) {
        @include media-breakpoint-down(xs) {  // Stack on XS
            dt { margin: 0; }
            dd { margin: 0 0 $modal-item-v-spacing-xs; }
        }
    }
}

.print-container .description-list {
    $dt-width: 240px;
    dt { width: $dt-width; }
    dd { width: calc(100% - #{$dt-width}); }
}
