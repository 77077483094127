@import '~css/base.scss';

.card-input-form { margin: 0 0 pxToRem(24);

    .card-logos { margin: 0 0 pxToRem(24);
        > img { margin: 0 1rem 0 0; width: pxToRem(40); height: pxToRem(40); } 
    }

    .icon-question { font-size: pxToRem(20); }

    .no-bottom-margin { margin-bottom: 0; }
}

#tip-surcharge table.surcharges {
    td:last-child { text-align: right; }
}
