@import '~css/base.scss';

.link-token-customer-page.hidden {
    display: none;
}

.link-token-customer-confirmation {
    .card-item-container {
        margin-bottom: 1.5rem;
    }
}