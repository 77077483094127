@import '~css/base.scss';
@import './InvoiceSettings.scss';

.invoice-detail-input {
    width: 100%;
    margin-right: pxToRem(32);
    margin-bottom: pxToRem(48);

    @include media-breakpoint-down(xs) {
        margin-bottom: pxToRem(24);
    }
}