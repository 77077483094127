@import 'css/base.scss';

.invoice-info-status {
    word-break: break-all;
}

.invoice-details-container {
    margin-bottom: pxToRem(16);

    @include media-breakpoint-down(xs) {
        display: flex;
        flex-direction: column;
        margin-bottom: pxToRem(0);
    }
}

.invoice-details-links {
    width: fit-content;
    margin-right: pxToRem(24) !important;
    
    @include media-breakpoint-down(xs) {
        margin-bottom: pxToRem(16) !important;
    }
}

.invoice-transaction-history-list {
    td {
        word-break: break-all;
    }

    // Status
    th:nth-child(1),
    td:nth-child(1) {
        width: pxToRem(153);
    }

    // Receipt number
    th:nth-child(2),
    td:nth-child(2) {
        width: pxToRem(210);
        a {
            color: $color-link !important;
        }
    }

    // Transaction date
    th:nth-child(3),
    td:nth-child(3) {
        width: pxToRem(269);
    }

    // Amount
    th:nth-child(4),
    td:nth-child(4) {
        width: pxToRem(150);
    }
}
