@import "css/constants/colors.scss";
@import 'css/constants/mixins.scss';


@mixin text-hero            { font-size: pxToRem(32); }

@mixin text-large1          { font-size: pxToRem(24); }
@mixin text-large1-emphasis { @include text-large1; font-weight: bold; }
//@mixin text-large2        { font-size: pxToRem(24); }
@mixin text-large3          { font-size: pxToRem(20); }
@mixin text-large3-emphasis { @include text-large3; font-weight: bold; }
//@mixin text-large4        { font-size: pxToRem(20); }
         
@mixin text-base            { font-size: pxToRem(16); }
@mixin text-base-emphasis   { @include text-base; font-weight: bold; }
         
@mixin text-small1          { font-size: pxToRem(14); }
//@mixin text-small2        { font-size: pxToRem(14); }

@mixin text-secondary       { color: $color-dark-65; }

