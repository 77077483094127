@import 'css/constants/mixins.scss';
@import 'css/constants/layout.scss';
@import 'css/constants/text.scss';

.text-hero            { @include text-hero; }
.text-large1          { @include text-large1; }
.text-large1-emphasis { @include text-large1-emphasis; }
.text-large3          { @include text-large3; }
.text-large3-emphasis { @include text-large3-emphasis; }
.text-base            { @include text-base; }
.text-base-emphasis   { @include text-base-emphasis; }
.text-small1          { @include text-small1; }
.text-secondary       { @include text-secondary; }

body { @include text-base; font-family: 'CBASans'; line-height: 1.5; color: $color-text; }

h1, h2, h3, h4, p { margin: 0 0 $element-margin-bottom; }

p:last-child { margin-bottom: 0; }

h1 { @include text-large1-emphasis; }
h2, h3 { margin-top: 3rem; @include text-large3-emphasis;
    &:first-child { margin-top: 0; }
}
h4 { @include text-base-emphasis; }

a { @include linkStyle; }

.body-container, .public-body-container {
    ul, ol { margin: pxToRem(8) 0 pxToRem(24); padding-left: 1.5rem;
        li { margin: pxToRem(4) 0; }
    }
}


/* #region ===== Font Declaration ===== */

@font-face {
    font-family: 'CBASans';
    src: url('~fonts/CBABeaconSans-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'CBASans';
    font-weight: bold;
    src: url('~fonts/CBABeaconSans-ExtraBold.otf') format('opentype');
}

@font-face {
    font-family: 'CBASans';
    font-style: italic;
    src: url('~fonts/CBABeaconSans-Italic.otf') format('opentype');
}

@font-face {
    font-family: 'CBASans';
    font-weight: bold;
    font-style: italic;
    src: url('~fonts/CBABeaconSans-ExtraBoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'CBASans';
    font-weight: 900;
    src: url('~fonts/CBABeaconSans-ExtraBold.otf') format('opentype');
}

@font-face {
    font-family: 'CBASans';
    font-weight: 900;
    font-style: italic;
    src: url('~fonts/CBABeaconSans-ExtraBoldItalic.otf') format('opentype');
}

/* #endregion */