@import 'css/base.scss';

.masked-card-number-field {
    $ellipsisWidth: pxToRem(16);
    $noEllipsisWidth: pxToRem(4);
    $suffixWidth: 40%;

    &.compact {
        max-width: pxToRem(220);
        $suffixWidth: 35%;
    }

    span.ellipsis { display: inline-block; width: $ellipsisWidth; text-align: center; }

    .textbox.card-prefix { 
        display: inline-block; 
        
        &.ellipsis { width: calc(100% - #{$suffixWidth} - #{$ellipsisWidth}) } 
        &.noEllipsis { 
            width: calc(100% - #{$suffixWidth} - #{$noEllipsisWidth});
            margin-right: #{$noEllipsisWidth}
        } 
    }

    .textbox.card-suffix { display: inline-block; width: $suffixWidth; }
}
