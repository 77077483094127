.add-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .icon.icon-card {
        width: 56px;
        height: 56px;
        margin-right: 1ch;
    }

    h2 {
        margin-bottom: 0;
    }
}