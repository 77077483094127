@import 'css/base.scss';

.transaction-details-page {
    .card-item-contents {
        img.card-logo { height: pxToRem(24); }
    }

    .btn-receipt{
        margin-top: 2rem;
    }

    @include media-breakpoint-down(sm) {
        .btn-subtle.header-link-button { text-align: left !important; }
        .btn-receipt { text-align: left !important; }
    }
}

.top-margin {
    margin-top: 2rem;
}