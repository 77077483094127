@import 'css/base.scss';

#tip-cvn .grid-container {
    display: grid;
    grid: auto auto auto / 1fr 1fr;
    grid-auto-flow: column;
    grid-gap: 0.5em 1.5em;

    h4 { margin: 0 }
}



