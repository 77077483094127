@import 'css/base.scss';

.preview-container {
    > div {  
        border: 1px solid $color-light-20; 
        transform-origin: top left;
    }

    &:not(.mobile) > div { width: 1280px; }
    &.mobile > div { width: 400px; }

    // Note: The preview currently only has 2 preset sizes. If in the future the preview needs to be fully
    // responsive we might be able to use something like: https://philipwalton.com/articles/responsive-components-a-solution-to-the-container-queries-problem/
}