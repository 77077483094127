@import 'css/base.scss';

.biller-list-container {
    margin-bottom: pxToRem(24);

    .biller-list-description {
        margin-bottom: pxToRem(16);
    }

    > .padded-container:first-child {
        margin-bottom: 1.5rem;

        a.biller {
            &:link,
            &:visited,
            &:active,
            &:hover {
                // Reset all link text
                text-decoration: none !important;
                color: black;
            }

            &:hover > .padded-container {
                @include cardDropShadow();
            }

            .biller-status-and-code {
                display: flex;

                .icon-text-container {
                    margin-bottom: 0.5rem;
                }

                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                    margin-bottom: 0.5rem;
                }
                @include media-breakpoint-up(sm) {
                    flex-direction: row;
                    .icon-text-container {
                        width: 6em;
                    }
                }
            }
        }
        .add-biller {
            cursor: pointer;
            &:hover {
                @include cardDropShadow();
            }
        }
    }
}
