@import 'css/base.scss';

.contact-time-field {
    .contact-time-selector{
        display: flex;
        align-items: center;
        margin-top: 0.75rem;

        @include media-breakpoint-down(sm) { 
            margin-top: 1.5rem
        }
    }
}