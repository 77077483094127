@import 'css/base.scss';

.padded-container {
    border-radius: 5px;

    &:not(.no-padding) {
        &.blue-border { border: pxToRem(2) solid $color-utility-blue; }
        &.grey-border { border: pxToRem(1) solid $color-light-20; }

        @include paddedContainer();

        &.less-padding { padding: 1rem; }
        &.less-margin { margin-bottom: pxToRem(24); }

        > .content {
            > *:last-child { margin-bottom: 0; }
        }
    }

    > .header {
        display: flex; justify-content: space-between; align-items: center;
        flex-wrap: wrap;

        &:not(.no-divider) {
            margin-bottom: 1.5rem;
            border-bottom: 1px solid $color-light-20;
        }

        .title-wrapper {
            display: flex;

            .icon { flex-shrink: 0; margin: -0.15em 0.3em 0 0; font-size: pxToRem(24); }
            h2 { @include text-large1-emphasis; margin: 0 0 1.5rem 0; }
            .title-suffix { margin-left: 1rem; line-height: 2rem; }
        }

        .button-wrapper {
            > * { margin-bottom: 1.5rem; }
        }

        @include media-breakpoint-only(xs) {
            display: block;

            .title-wrapper {
                display: block;
                .icon { margin-bottom: pxToRem(4); }
            }
        }
    }

    &.switch-on-right {
        position: relative;

        .switch {
            @include media-breakpoint-up(sm) {
                position: absolute; top: 2rem; right: 2rem;
            }
        }
    }
}