@import 'css/base.scss';

.invoice-records {
    td { word-break: break-all; }

    // Status
    th:nth-child(1),
    td:nth-child(1) {
        width: pxToRem(126);
    }

    // Invoice number
    th:nth-child(2),
    td:nth-child(2) {
        width: pxToRem(161);
        a {
            color: $color-link !important; 
        }
    }

    // Order number
    th:nth-child(3),
    td:nth-child(3) {
        width: pxToRem(156);
    }

    // Invoice date
    th:nth-child(4),
    td:nth-child(4) {
        width: pxToRem(151);
    }

    // Amount
    th:nth-child(5),
    td:nth-child(5) {
        width: pxToRem(180);
    }
}