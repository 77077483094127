@import 'css/base.scss';

.date-range-preset-field {
    @include media-breakpoint-down(md) { 
        .date-picker { margin-bottom: 1rem; }
        .date-radios { padding-top: pxToRem(8); }
    }

    @include media-breakpoint-up(lg) { 
        .date-radios { padding-top: pxToRem(12); }
    }
}
