@import 'css/base.scss';

@mixin inlineSelectors($h-gap: 3rem) {
    display: flex;
    flex-wrap: wrap;
    margin-right: (-1 * $h-gap);

    .form-check { margin-right: $h-gap; }
}

@mixin columnedSelectors($numColumns: 2) {
    display: flex;
    flex-wrap: wrap;

    .form-check { min-width: calc(100% / $numColumns);}
}

@mixin evenlySpacedSelectors($colXl, $colLg, $colSm, $colXs) {
    @include media-breakpoint-only(xl) { @include columnedSelectors($colXl);  }
    @include media-breakpoint-down(lg) { @include columnedSelectors($colLg);  }
    @include media-breakpoint-down(md) { @include columnedSelectors($colSm);  }
    @include media-breakpoint-down(xs) { @include columnedSelectors($colXs);  }
}


.selector-group {
    margin-bottom: -1rem;  // Cancel out excessive bottom margin from the FormCheck's. FormGroup will add more bottom margin.

    &:not(.compact) {
        &.inline { @include inlineSelectors(); }
        &.inline-up-sm { @include media-breakpoint-up(sm) { @include inlineSelectors(); } }
        &.inline-up-lg { @include media-breakpoint-up(lg) { @include inlineSelectors(); } }
    }
    &.compact {
        &.inline { @include inlineSelectors(1.5rem); }
        &.inline-up-sm { @include media-breakpoint-up(sm) { @include inlineSelectors(1.5rem); } }
        &.inline-up-lg { @include media-breakpoint-up(lg) { @include inlineSelectors(1.5rem); } }
    }

    &.inline-xl-5 { @include evenlySpacedSelectors(5, 4, 3, 2); }
    &.inline-lg-3 { @include evenlySpacedSelectors(3, 3, 2, 1); }
}

.label-wrapper + .selector-group { margin-top: 0.5rem; }