@import 'css/base.scss';

.settlement-summary-report {
    table td.net { font-weight: bold; }

    dt.net {
        &, & + dd {
            border-top: 1px solid $color-text !important;
            border-bottom: 1px solid $color-text;
            background-color: $color-light-05;
        }
    }
}