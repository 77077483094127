@import '~css/base.scss';

footer {
    flex: none;
    box-shadow: $card-box-shadow;
    margin-top: auto; left: 0; bottom: 0;
    background-color: white; color: $color-text;
    
    .footer-content { margin: pxToRem(24) pxToRem(30); 
        .footer-text { margin: 0; @include text-small1; }
    }

    .footer-logo { width: pxToRem(172); }

    @include media-breakpoint-down(sm) {
      .footer-logo { margin-top: pxToRem(12); }
    }
    
    @include media-breakpoint-only(md) {
      .footer-content { display: flex; justify-content: center;
        > * { align-self: center; }
    
        .footer-text { max-width: 526px; }
        .footer-logo { margin-left: pxToRem(32); }
      }
    }
    
    @include media-breakpoint-up(lg) {
      .footer-content { display: flex; justify-content: center; margin-left: 1rem; margin-right: 1rem;
        > * { align-self: center; }
    
        .footer-logo { margin-left: pxToRem(28); }
      }
    }
}

