@import '~css/base.scss';

.app {
    background:$color-light-05;
    min-height: 100%;
    flex-grow: 1;
}

.app-body {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    flex: 1 0 auto;
    -webkit-flex: 1 0;
    -ms-flex: 1 0 auto;
}

.menu-body-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    flex: 1 0 auto;
    -webkit-flex: 1;
    -ms-flex: 1 0 auto;
}

.nav-container {
    display:none;
}

.body-container {
    flex: 1;
    width: 320px;
}

.public-body-container {
    width: 100%;
}

@include media-breakpoint-up(md) {
    .app {
        background:url('~images/loginBackground.jpg');
        background-size: cover;
    }

    .nav-container {
        width: 250px;
        display: flex;
        min-height: 100%;
        background: $color-light-10;
        z-index: 1;
    }

    .public-body-container {
        //height: 100%;
        //text-align: center;
        //white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items:center;
    }

    .public-body-container::before {
        content: "";
        //display: inline-block;
        
        width: 0;
        height: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .menu-body-wrapper, .public-body-container {
        margin-top: $header-height;
    }
}