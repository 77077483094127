@import 'css/base.scss';

.card-list {
    // Quick filter
    .form-group.field-name { margin-bottom: 0.5rem; }

    .filter-fields { 
        > div {
            &:not(.active) { display: none; }
        }
    }

    @include media-breakpoint-down(sm) {
        .filter-fields .form-group > label { display: none; }
    }

    // Loading
    .loading-indicator-container { margin-bottom: 1rem; }

    // Cards
    &:not(.highlight-total) {
        .card-item-contents {
            > .actions { margin-bottom: 1rem; text-align: right; 
                button { margin-left: 2rem;
                    &:first-child { margin-left: 0 }
                }
                .form-check { margin-bottom: 0; }
            }

            > a { color: $color-text; text-decoration: none; }

            dl {
                &:last-child { margin-bottom: 0; }
        
                dt, dd { 
                    &:last-of-type { margin-bottom: 0; }
                }
            }
        }
    }

    &.highlight-total { 
        .card-item-container { 
            @include paddedContainer();

            .card-item-header { 
                padding: 0.5rem 1rem; 
                border-bottom: 1px solid $color-text; 
                font-weight: bold; 
            }

            .card-item-contents {
                padding: 0; background: none;

                dl { margin: 0;
                    dt, dd { margin: 0; border-top: 1px solid $color-light-10; 
                        &:first-of-type { border-top: none; }
                    }
                    dt { padding: 1rem 0 1rem 1rem; font-weight: normal; }
                    dd { padding: 1rem 1rem 1rem 0; font-weight: bold; }
                }

            }
        }
    }

    .cards .form-group { margin-bottom: 0; }
}
