@import '~css/base.scss';

.manage-declines-tray-page-size-drop-down {
    @include media-breakpoint-up(lg) {
        label {
            width: auto !important;
        }
        .dropdown {
            width: pxToRem(90) !important;
        }
    }
}

@include media-breakpoint-up(lg) {
    .masked-card-number-column {
        width: pxToRem(250) !important;
    }
}

.masked-card-number-inputs {
    display: flex;
    gap: 4px;
}

.card-item-contents {
    img.card-logo { height: pxToRem(24); }
}
