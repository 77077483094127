@import 'css/base.scss';

.shopping-cart-page{
    .products-table{
        padding-bottom: 3rem;
    }

    .button-wrapper {
        @include media-breakpoint-down(xs) {
            text-align: center;
        }
    }
}