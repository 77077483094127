@import 'css/constants/mixins.scss';

@media print {
    //@page { margin: 0; size: 210mm 297mm; }

    html { zoom: 200%; }

    header, footer, .nav-container { display: none; }

    input { @include placeholder { color: transparent !important; } }
}