@import 'css/base.scss';

.preview-layout {
    .header {
        background: white url('~images/default-branding-logo.png') no-repeat center center;
        height: 120px;
    }

    .footer {
        padding: 10px 0;
        text-align: center;
        font-size: 11px;

        .copyright-logo { 
            height: 15px;
            background: transparent no-repeat center center;
        }
    }
}
