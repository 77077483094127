@import 'css/base.scss';

.offset-for-inline-textbox {
    padding-top: 12px;
}

.frequency-section {
    margin-left: 30px;
}

// Override setting in PromotionCard
.click-here-button {
    margin-top: 0px !important;
}

.icon-question {
    float: right;
}