@import '~css/base.scss';

.container{
    display: flex;
    &.is-expanded {
        transform: rotateX(180deg);
    }
}

.container {
    .menu-item-expander {
        margin-top: 15px !important;
        background: url(~images/ArrowDown.svg) no-repeat;
    }
}

.container {
    .toggle-text {
        width: fit-content;
        padding-top: 9px;
        margin-right: 16px;
        margin-left: -15px;
    } 
}
