@import '~css/base.scss';

$header-menu-width: pxToRem(320);
$side-menu-width: pxToRem(250);
$border-width: 2px;
$border-color: $color-light-20;

@mixin menu-border($color){
    border-bottom: $border-width $color solid;
    border-right: $border-width $color solid;
}

.menu-item-content {
    @include text-base-emphasis;
    width: $header-menu-width;
    height: $menu-item-height;
    text-decoration: none;
    cursor: pointer;
    background-color: $color-dark-80;
    color: $color-light;
    line-height: 64px;
    padding-left:24px;
    @include menu-border($border-color);

    &.sub-menu-item {
        font-weight: normal;
        line-height: 64px;
        background-color: $color-light-05;
        color: $color-text;

        &.active {
            font-weight: bold;
            background-color: $color-light-05;
            border-left: $color-primary 6px solid;
            padding-left: 18px;
            @include menu-border($border-color);
        }
    }
}

.nav-menu-item-content {
    width: $side-menu-width;
    background-color: $color-light-10;
    color: $color-text;

    &.active {
        background-color: $color-dark-80;
        color: $color-light;
        @include menu-border($color-dark-80);
    }

    &.sub-menu-item {
        @include text-base;
        line-height: 64px;
        background-color: $color-light-05;

        &:hover, &.active { font-weight: bold; }

        &.active {
            line-height: 64px;
            background-color: $color-light-05;
            color: $color-text;
            border-left: $color-primary 6px solid;
            padding-left: 18px;
            @include menu-border($border-color);
        }
    }
}

