@import '~css/base.scss';

$component-margin-top: 12px;

.pagination-container {
    @include media-breakpoint-up(lg) {
        margin: 0;
        float: right;

        > * { float: left; }
    }

    @include media-breakpoint-down(md) {
        margin-top: 1.5rem;
        display: flex; flex-direction: row; justify-content: center;
    }

    .page-selection-input-container {
        .page-selection-input {
            display: inline-block;
            border: 1px solid $color-light-20; border-radius: 3px;
            padding-left: 6px; padding-right: 6px;
            width: 50px;
            text-align: center;
        }
    }
    
    .page-arrow {
        cursor: pointer;
        margin-top: $component-margin-top;
        width: 24px; height: 24px;
        background: url(~images/ArrowDown.svg) no-repeat;

        &.left-page-arrow { margin-right: 16px; @include rotate(90); }
        &.right-page-arrow { margin-left: 16px; @include rotate(270); }
    }
    
    .btn.go-button {
        margin: 0 0 0 pxToRem(12);
        min-width: 2.5rem !important;
        color: $color-link;
        cursor: pointer;

        @include media-breakpoint-down(xs) { width: auto; }
    }    
}

