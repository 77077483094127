@import 'css/base.scss';

.rename-batch-form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    
    .file-name-field {
        padding-right: 8px;
        width: 100%;
    }

    .file-type-field {
        padding-left: 8px;
        min-width: 140px;
    }
}