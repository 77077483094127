@import "~css/base.scss";

.dropdown {
    // The select
    > .rselect__control {
        border: $input-border-width solid $input-border-color; border-radius: 0; box-shadow: none; background: $color-light; color: $input-color; transition: all $input-transition-time;

        // The left part (containing the text)
        > .rselect__value-container { padding: 0 ($input-padding-x - $input-border-width); transition: all $input-transition-time;
            .rselect__single-value { max-width: calc(100% - #{pxToRem(14)}); }
            > div:not(.rselect__multi-value) { margin: 0; }
        }

        // The right part (containing the arrow)
        > .rselect__indicators {
            > .rselect__indicator-separator { display: none; }
            > .rselect__dropdown-indicator {
                padding: 0; width: ($input-height - ($input-border-width*2)); height: ($input-height - ($input-border-width*2)); transition: all $input-transition-time;
                background: url("~images/ArrowDown.svg") no-repeat; background-size: pxToRem(16); background-position: center;

                svg { display: none; }
            }
        }

        &:hover, &.rselect__control--is-focused, &.rselect__control--menu-is-open { 
            @include inputHighlight();
        }
    }

    // The options
    > .rselect__menu {
        margin: 0; border: $input-border-width solid $color-light-20; border-top-width: 0; border-radius: 0; box-shadow: none;

        > .rselect__menu-list {
            > .rselect__option { background: none; color: $input-placeholder-color;
                &.rselect__option--is-selected { color: $input-color; font-weight: bold; }
                &.rselect__option--is-focused { background-color: $color-dark-65; color: white; } 
                &.rselect__option--is-disabled { color: #ccc; /* Not provided/used by CBA */ }
            }
        }
    }

    &:focus { outline: none; }
    &.rselect--is-disabled {
        > .rselect__control { background: $input-disabled-bg; color: $color-dark-65; }
    }

    &.compact {
        .rselect__control {
            .rselect__value-container { padding: 0 8px; }
            .rselect__dropdown-indicator { width: 28px !important; }
        }
        .rselect__menu { width: auto; min-width: 100%; }
    }

    &.has-error {
        > .rselect__control { 
            @include inputHighlight($color-error); 
        }
    }
}


