.icon-text-container {
    display: flex;

    margin-bottom: 1.5em;

    .icon {
        min-width: 1rem;
        height: 1.5em; // Matches icons height to the default line-height to keep it vertically centered
        flex-shrink: 0;
    }
  
    .loading-indicator-container {
        display: flex;
        align-items: center;
        margin-right: 0.5em;

        .loading-indicator {
            height: 1.2em;
        }
    }
}

.icon-text-container:last-child {
    margin-bottom: 0;
}

// for when an icon text is within a form-group, removing the bold styling.
.form-group label .icon-text-container { 
    font-weight: normal; 
}