.page-section:not(.full-width) {
    max-width: 1477px;
}

.additional-payment-draggable-card {
    background-color: #FFF;
}

.additional-payment-title {
    padding-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
}

.same-as-above-switch-container {
    display: flex;
    gap: 5px;
}

.additional-payment-actions {
    justify-content: end;
    margin-right: 5px !important;
}

.delete-additional-payment-button {
    cursor: pointer;
    padding-top: 2rem;
    display: flex;
    justify-content: flex-end;

    img {
        margin-right: 5px;
    }
}

label .tooltip-trigger {
    margin-left: 0;
}

.new-additional-payment-button {
    justify-content: flex-end;
}

.additional-payments-empty {
    padding-left: 1rem;
}
