@import 'css/base.scss';

.search-payment-table {
    th, td {
        overflow-wrap: anywhere;
    }

    .button-container {
        width: calc(100% + 2rem);
        margin-left: -1rem;
        margin-right: -1rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        float: none;
    }

    button {
        flex: 1 1;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media screen and (min-width: 600px) {
    .search-payment-modal .modal-dialog {
        width: 800px!important;
    }
}