/*********************************************************
**** Keep these 2 files the same
**** HPP eform_v2.css
**** Payment eform_v2.css
**********************************************************/

.eform-form {
    overflow-wrap: break-word;
}

.eform-form .errors {
    clear: both;
    padding-top: 4px;
}

.eform-form .errors>div {
    clear: both;
    font-weight: bold;
    color: #de1111;
    //padding: 4px;
    border-radius: 4px;
}

/* Tooltip */
#tooltip,
.tooltip {
    background-color: #253E51;
    border: 4px #999 solid;
    border-radius: 7px;
    padding: 10px 15px;
    width: 400px;
    display: none;
    color: #fff;
    text-align: left;
    font-size: 10px;
}

#tooltip ol,
.tooltip ol {
    padding: 0 0 0 15px;
    margin: 0;
}

#tooltip li,
.tooltip li {
    margin: 0 0 7px 0;
    text-align: justify;
    line-height: 18px;
}

#tooltip .sublist,
.tooltip .sublist {
    padding: 0 0 0 20px;
}

#tooltip .ui-icon,
.tooltip .ui-icon {
    display: inline-block;
}

.ui-icon {
    height: 16px;
    width: 16px;
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}

.ui-icon-s {
    background-image: url(../templates/images/ui_icons2_16x16.png);

}

.ui-icon-s-help {
    background-position: -160px -128px;
}


/*********************************************************
**** Keep
**** HPP Views\Base\DefaultViews\eform_v2.css
**** Payment Views\Base\DefaultViews\eform_v2.css
**** BackOffice Views\Base\UI\eform_view.css
**** the same below this line
**********************************************************/

.eform-form {
    font-size: 14px;
}

/* bootstrap extracts to match HPP styling :( */
.eform-ctrl-input {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

input.eform-ctrl-input,
select.eform-ctrl-input {
    height: 34px;
}

.eform-ctrl-input:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.eform-ctrl-input::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.eform-ctrl-input:-ms-input-placeholder {
    color: #999;
}

.eform-ctrl-input::-webkit-input-placeholder {
    color: #999;
}

.eform-ctrl-input::-ms-expand {
    background-color: transparent;
    border: 0;
}

.eform-ctrl-input[disabled],
.eform-ctrl-input[readonly] {
    background-color: #eee;
    opacity: 1;
}

.eform-ctrl-input[disabled] {
    cursor: not-allowed;
}

/* generic */
.eform-ctrl {
    padding: 4px 4px 4px 4px;
    margin-top: 5px;
    margin-bottom: 5px;

    &.validation-error {
        background-color: #fceded;

        input,
        select,
        textarea {
            border-color: #de1111;

            &.not-mandatory {
                border-color: #ccc;
            }
        }

        input:not([value=""]) {
            border-color: #ccc;
        }

        select:has(option:checked:not([value=""])) {
            border-color: #ccc;
        }
    }
}

.eform-ctrl-label {
    font-weight: bold;
    display: inline-block;
    max-width: 100%;
}

.eform-required:after {
    content: " *";
    color: red;
}

/* checkbox, radio */
.eform-ctrl-checkbox-item,
.eform-ctrl-radio-item {
    margin: 0 5px 5px 5px;
}

.eform-ctrl-checkbox-item>*,
.eform-ctrl-radio-item>* {
    vertical-align: middle;
    margin: 0;
    font-weight: normal;
}

.eform-ctrl-checkbox-item>input,
.eform-ctrl-radio-item>input {
    margin-right: 2px;
}

.eform-ctrl-checkbox-item [type="checkbox"],
.eform-ctrl-radio-item [type="radio"] {
    padding: 0;
}

/* dropdown */
.eform-ctrl-dropdown-othersection {
    margin-top: 4px;
}

/* other */
.eform-ctrl-input-other,
input.eform-ctrl-input-other[type="text"] {
    width: 10em;
    display: inline-block;
}

/* heading */
.eform-ctrl-heading-heading {
    font-weight: bold;
}

/* address */
.eform-ctrl-address-row {
    padding-bottom: 5px;
}

/* long answer */
.eform-ctrl-long-textarea {
    resize: none;
}

.eform-ctrl-text-text {
    min-height: 1em;
}

/* submit */
.eform-ctrl-submit-btn {
    display: block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 100%;
    overflow-wrap: break-word;
    white-space: normal;
}

.eform-ctrl-submit-btn:focus,
.eform-ctrl-submit-btn:active:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.eform-ctrl-submit-btn:hover,
.eform-ctrl-submit-btn:focus {
    color: #333;
    text-decoration: none;
}

.eform-ctrl-submit-btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.eform-ctrl-submit-btn[disabled] {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

button.eform-ctrl-submit-printbtn,
button.eform-ctrl-submit-clearbtn {
    background-color: #A6A4A1;
}

.eform-ctrl-submit {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 10px;
}

.eforms-content-container {
    position: relative;
}

.other-checkbox,
.other-radio-button {
    margin-left: 5px;
}

.top {
    .other-checkbox,
    .other-radio-button {
        margin-left: 0;
    }
}

@media print {
    .eform-ctrl-submit {
        display: none;
    }
}