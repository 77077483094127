@import 'css/base.scss';

.single-column-form-container {
    max-width: 460px;

    @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
    }
}