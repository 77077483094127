@import 'css/base.scss';

.batch-file-status-error {
    word-break: normal;


    .error {
        margin-bottom: 0;
    }
}

.pointer-cursor {
    cursor: pointer;
}

.default-cursor {
    cursor: default;
}