@import 'css/base.scss';

.button-container { 

    &:not(.no-top-margin) { margin-top: pxToRem(40); }

    span:not(:last-child) { margin-right: 1.5rem; }

    &.has-divider {  
        border-top: 1px solid $color-light-20;
        padding-top: 1.5rem;
    }

    &.justify {
        @include media-breakpoint-down(md) {
            @include justifyButtons; 
        }
    }
    &.justify-override {
        @include justifyButtons; 
    }
}
