@import '~css/base.scss';

//$header-height: pxToRem(64); // already defined in layout.scss
//$header-z: 900; // already defined in layout.scss

$menu-background-color: $color-dark-80;
$menu-text-color: $color-light;
$menu-width: pxToRem(320);
$nav-menu-width: pxToRem(250);
$menu-submenu-background-color: $color-light-05;
$menu-submenu-text-color: $color-dark-80;

.menu-container {
    position:fixed;
    bottom: 0;
    top: $header-height;
    width: $menu-width;
    z-index: $menu-z;
    overflow-y: auto;
    overflow-x: hidden;

    &.sub-menu {
        position: static;
    }

    &.menu-left {
        left: -340px;
        transition: left 0.15s;
    }

    &.menu-left-show {
        left: 0;
    }

    &.menu-right {
        right: -340px;
        transition: right 0.15s;
    }

    &.menu-right-show {
        right: 0;
    }

    &.nav-menu {
        position: relative;
        margin-top: 0;
        top:0;
        left:0;
        width: $nav-menu-width;
    }

    .menu-component-container {
        position: fixed;

        .menu-component {
            @include text-base;
            padding: 0;
            color: $menu-text-color;
            margin-block-start: 0;
            margin-block-end: 0;
            width: $menu-width;

            &.sub-menu {
                color: $menu-submenu-text-color;
                position: relative;
            }
        }
    }

    

    .menu-component {
        @include text-base;
        padding: 0;
        color: $menu-text-color;
        margin-block-start: 0;
        margin-block-end: 0;
        white-space:nowrap;  // add this line
        font-size:0;   // add this line

        &.sub-menu {
            color: $menu-submenu-text-color;
            position: relative;
        }

        &.nav-menu-component {
            width: $nav-menu-width;
        }
    }
}