@import 'css/base.scss';

.schedule-end-field .form-check-input[type='radio'] + label {
    display: flex;
    align-items: center;

    &:before, &:after {
        top: initial;
    }

    .end-after-field {
        margin-bottom: 0;
        margin: 0 0.7rem 0 0.7rem;
    }
    .end-date-field {
        margin-bottom: 0;
        margin: 0 0.7rem 0 0.7rem;
    }
}

.schedule-details-form-fields {
    max-width: 460px;

    @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
    }
}