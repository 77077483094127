@import '~css/base.scss';

.schedules-page {
    &.hidden { display: none; }

    .filter-container {
        label { font-weight: normal !important; }

        @include media-breakpoint-up(lg) {
            &.merchant-filter-container {
                $merchantLabelWidth: 90;
                label { margin: 0 !important; width: pxToRem($merchantLabelWidth) !important; }
                .dropdown { width: calc(100% - #{pxToRem($merchantLabelWidth)}) !important; }
            }
            
            &.results-filter-container {
                label { width: auto !important; }
                .dropdown { width: pxToRem(90) !important; }
            }
        }
    }

    .dropdowns {  
        @include media-breakpoint-only(md) {
            max-width: pxToRem(540);
        }
    }

    td .col-break { word-break: break-all; }
}