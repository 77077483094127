@import 'css/base.scss';

.nav-tabs {
    display: flex;
    border-bottom: 4px solid $color-light-20;

    .nav-item {
        margin-bottom: -6px;
        padding: 8px 0;
        flex-grow: 1;
        flex: 1;
        text-align: center;

        &.nav-link {
            color: $color-text;
            //&:focus { outline: none; }
        }

        &.active {
            border-bottom: 8px solid $color-primary;
            font-weight: bold;
        }
    }
}

.tab-content { margin: 0 0 1rem; padding: 1.5rem 0 0;
    > .tab-pane { display: none;
        &.active { display: block; }
    }
}