@import 'css/base.scss';

.table-container {
    table {
        &:last-child { margin-bottom: 0; }

        &:not(.highlight-total) { @include tableDefault(); }
        &.highlight-total { @include tableHighlightTotal(); }

        thead > tr.filters th.check {
            padding-left: 0.75rem;
        }

        td > .form-group { margin-bottom: 0; }
    }

    .button-container { float: left; }
}
