@import '~css/base.scss';

.view-schedule-page {
    .page-header-container .page-header .header-left.has-subtitle h1 {
        margin-bottom: 1.5rem;
    }
    .header-subtitle {
        dl.description-list {
            dt::after {
                content: ':';
            }
        }
    }
    .card-logo {
        max-height: pxToRem(24);
    }

    .overdue-payments-banner {
        color: $color-error;

        .btn-link {
            margin-left: 1rem;
        }
    }
}
