@import 'css/base.scss';

.products-table {
    table.table {
        th, td {
            &:first-child { padding-left: 1rem; }
            &:last-child { padding-right: 1rem; }

            &.currency { min-width: pxToRem(116); }
        }
        td { padding-top: 1rem; padding-bottom: 1rem; }
    }

    .total {
        font-weight: bold;
        font-size: 20px;
    }

    .flex-container {
        display: flex;
        justify-content: space-between;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 8px;
    }

    .address-type {
        font-weight: bold;
    }

    @include media-breakpoint-down(md) {
        .product-thumbnail-container {
            $img-width: pxToRem(100);
            margin-left: calc(50% - #{$img-width} )
        }

        .quantity-controller-wrapper {
            margin-left: 0 !important;

            button {
                display: none;
            }

            .quantity-controls {
                justify-content: flex-start !important;
            }

            &.shopping-cart{
                text-align: left;
            }
        }

        .remove-button {
            float: right;
        }
    }

    .quantity-controller-wrapper {
        margin: 0 auto;
        text-align: center;
        .quantity-value-controller{
            .form-group{
                margin: 0 auto;
                .label-wrapper{
                    display: none;
                }
            }
        }
    }

}