@import 'css/base.scss';

ul.checkout-steps {
    margin: 1rem 0 2rem 0 !important; list-style: none; padding-left: 0 !important;

    > li {
        @include media-breakpoint-up(lg) {
            display: inline-block; margin: 0 2rem 0 0 !important;
        }
        @include media-breakpoint-down(md) {
            margin: 0 0 0.5rem 0 !important;
        }

        .badge { margin: 0 0.25rem 0 0; background-color: $color-dark-65; color: $color-light; }
        a .badge { background-color: $color-link; }

        &.active { font-weight: bold; }
        &.disabled { color: $color-dark-65; 
            .badge { background-color: $color-light-20; }
        }

    }
}