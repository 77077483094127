@import '~css/base.scss';

.inline-content {
    display: flex;
    margin: 2rem 0;
    text-align: right;
    justify-content: space-between;
}

.inline-content+.message-container {
    /* Apply this style when .inline-content is displayed */
    margin-bottom: -1rem;
    margin-top: -2rem;
}

.message-container {
    margin-bottom: -1rem;
    margin-top: 2rem;
}

.resend-code {
    margin-left: auto;
}