@import '~css/base.scss';

.reorderablelist ul {
    padding: 0 !important;

    li:nth-child(odd) {
        background-color: $color-light-10;
    }

    li:nth-child(even) {
        background-color: $color-light;
    }
}

.reorderablelist-item {
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
    }

    min-height: pxToRem(56);
    padding: pxToRem(16);

    // Simulate the radio input's hover state
    label:before {
        &:hover {
            border: 0.25rem solid $color-focus;
        }
    }
}

.reorderablelist-radiolabel {
    display: flex;
    align-items: center;
    flex: 1 1 auto;

    @include media-breakpoint-down(xs) {
        width: 100%;
    }

    // Label
    :last-child {
        &:hover {
            cursor: pointer;
        }
    }
}

.reorderablelist-radio {
    height: 0;
    margin: -2rem 1rem 1.5rem pxToRem(-5);
}

.reorderablelist-controls {
    display: flex;

    @include media-breakpoint-down(xs) {
        min-height: pxToRem(56);
        margin: 0 0 pxToRem(-16) 0;
    }
}

.reorderablelist-nodata {
    display: flex;
    align-items: center;

    padding: pxToRem(8);
    min-height: pxToRem(56);
    background-color: $color-light-10;
}

.reorderablelist-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: pxToRem(56);

    &:hover {
        cursor: pointer;
    }

    img {
        height: pxToRem(18);
        width: pxToRem(18);
    }
}

.reorderablelist-icon__up {
    transform: rotate(90deg);
}

.reorderablelist-icon__down {
    transform: rotate(-90deg);
}
