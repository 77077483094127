@import 'css/base.scss';

.expiry-wrapper, .form-group.expiry-wrapper  {
    min-width: pxToRem(100);

    > input { 
        display: inline-block; 
        width: 50%; 
        text-align: center; 
    } 
}