@import '~css/base.scss';

.manage-users-page {
    &.hidden { display: none; }

    td .col-break {
        word-break: break-all;
    }
}

.manage-users-pagination-control {
    margin-bottom: pxToRem(24);
}

.manage-users-deeplink {
    clear: both;
}

.Redbackground-button{
    background-color: #FF0000 !important;
    color: #FFFFFF !important;
    border: none !important;
}

.background-white{
    background-color: white;
}

.paragraph-margin{ 
    padding-left: 15px;
    padding-top: 15px;   
}

