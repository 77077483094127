@import '~css/base.scss';

$border-radius: 4px;

.card-item-container {
    margin-top: 16px; padding: 0; border: 1px solid $color-light-10; border-radius: $border-radius;
    @include cardDropShadow();

    &:first-of-type { margin-top:0px; }

    .card-item-header {
        padding: 16px;
        border-bottom: 1px $color-light-05 solid;
        border-radius: $border-radius $border-radius 0 0;
        //height: 54px;
        background: white;
    }
    
    .card-item-contents {
        padding: 16px; border-radius: $border-radius;
        background-image: linear-gradient(to top, $color-light, $color-unnamed-light);
    }
    .card-item-header + .card-item-contents { border-radius: 0 0 $border-radius $border-radius; }


    &.selectable {
        .select-bar {
            background: $color-light-10;
            line-height: 2rem;
            text-align: center;
            font-weight: bold;
            
            > .icon { vertical-align: middle; }
        }

        &:not(.selected) .select-bar:hover { background: $color-light-20; cursor: pointer; }

        &.selected { border: 1px solid $color-dark-80; 
            .select-bar {
                background: $color-dark-80;
                color: $color-light;
            }
        }
    }

}

.card-item-details {
    tr {
        height: 32px;
    }

    td:first-child {
        @include text-base-emphasis;
        padding-right: 30px;
    }
}


.print-container {
    .card-item-container { border: none; box-shadow: none;
        
        .card-item-header { padding: 0 0 7px; border-bottom: 1px solid #999; font-size: 14px; font-weight: bold; }

        .card-item-contents { padding: 10px 0 0 0; background: white; }
    }
}