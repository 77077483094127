@import '~css/base.scss';

.iservices-section-heading {
    h2 {
        margin: 0;
        line-height: pxToRem(28);
        padding: 0 0 pxToRem(16) 0;
    }
}

.iservices-email-input-heading {
    max-width: pxToRem(460);
}

.iservices-characters-remaining {
    color: $color-dark-65;
    margin: pxToRem(2) 0 pxToRem(28);
}

.iservices-upload-error-filename {
    display: flow-root;
    justify-content: center;
}

.iservices-error-message {
    margin-top: pxToRem(15);
    margin-bottom: pxToRem(14);
    color: $color-utility-red;
}

.iservices-file-indicator-container {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
        flex-direction: column;
    }
}

.iservices-file-indicator {
    display: flex;
    align-items: center;
    margin-bottom: pxToRem(16);
    padding-left: pxToRem(24);
    padding-right: pxToRem(24);
    max-width: calc(100% - 180px - 24px);

    @include media-breakpoint-down(xs) {
        padding-left: 0;
        margin-bottom: pxToRem(24);
        align-self: flex-start;
        max-width: 100%;
    }
}

.iservices-file-indicator-button {
    max-width: pxToRem(180);

    @include media-breakpoint-down(xs) {
        margin-right: 0 !important;
        max-width: 100%;
    }
}

.iservices-file-indicator-icon {
    min-width: pxToRem(16);
}

.iservices-file-indicator-text {
    height: pxToRem(22);
    margin-bottom: 0;
    padding-left: pxToRem(10);
    overflow-wrap: break-word;
    max-width: 100%;
}
