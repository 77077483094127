@import "~css/base.scss";

.textbox { @include textbox(); }

input.no-number-spinner {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none; margin: 0;
    }
}

input[type=number] {
    -moz-appearance:textfield;
}

input.form-control:disabled {
    background-color: transparent;
    color: #231f20;
}

.textarea-no-resize {
    resize: none;
}
