@import 'css/base.scss';

.send-receipt-form {
    position: relative;
    display: flex;
    flex-direction: column;

    //send receipt radio buttons
    .form-group {
        width: 75%;
    }

    .server-error {
        margin: pxToRem(8) 0 0;
        @include media-breakpoint-down(sm) { margin: 0; }
    }

    //submit field wrapper
    .field-wrapper {
        display: flex;
        align-items: flex-start;

        .btn { margin: 0; min-width: 136px; }

        .form-group {
            width: 100%;
            margin-bottom: 1rem;

            .label-wrapper { display: none; }
        }


        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: flex-end;

            .form-group { padding-right: 16px; margin-bottom: 0; }
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;

            .btn { width: 100%; }
        }
    }

    .sent-sucessfully { margin: 1rem 0 0;
        @include media-breakpoint-down(sm) { margin: 0 auto; }
    }

    #printButton {
        @include media-breakpoint-up(md) {
            position: absolute; right: 0; top: 0;
            margin: 0;
            line-height: 1.5rem; height: auto;
            min-width: 0;
        }
    }
}

.receipt-button-container {
    position: relative;
    #printButton {
        @include media-breakpoint-up(md) {
            position: absolute; right: 0; top: 0;
            margin: 0;
            line-height: 1.5rem; height: auto;
            min-width: 0;
        }
    }
}