@import 'css/base.scss';

.customer-list {
    td { word-break: break-all; }

    td.customer-code > a,
    dt.customer-code + dd { 
        color: $color-link !important; 
    }
}
