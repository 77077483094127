@import "~css/base.scss";

.tox.tox-tinymce {
    border: 0.0625rem solid #706d6e;
    border-radius: 0 !important;

    &.has-error {
        @include inputHighlight($color-error);
        outline-offset: initial;
    }
}

.tox-tinymce:hover,
.tinymce-editor-focused {
    outline: 0.0625rem solid #1175b5 !important;
    box-shadow: none !important;
}

.tox-tinymce--disabled .tox-edit-area__iframe {
    background-color: transparent !important;
}
