@import 'css/base.scss';

.reference-title {
    padding-bottom: 1.5rem;
}

.reference-items dt,
.reference-items dd {
    margin-bottom: 2rem;
}

.reference-items {
    display: flex;
    flex-direction: column;
    .description{
        word-wrap: break-word;
    }
}

.description-list:not(.two-column) dt:last-of-type {
    margin-top: 4.5px;
}

.reference-divider {
    margin-top: 3rem;
}

.reference-edit-button {
    background-color: azure;
}