@import 'css/base.scss';

.schedule-field {

    .selector-group { 
        align-items: center;

        > .form-check:nth-child(2) > label {  // The 2nd (schedule) radio
            width: 100%;

            @include media-breakpoint-up(sm) {
                display: flex; align-items: center;
        
                // Move the radio button down
                $radio-position: calc(($input-height - $chk-size) / 2);
                &:before { top: $radio-position; }
                &:after { top: $radio-position + pxToRem(6); }

                .date-picker { margin: 0 0 0 1rem; width: pxToRem(300);
                    @include media-breakpoint-down(md) { width: pxToRem(100); flex-grow: 1; }
                }
            }
        }
    }

}