@import '~css/base.scss';

$width: 56px;

#btn-top {
    display: none; position: fixed; bottom: 15px; right: 15px;
    padding: calc($width / 2); border: none; outline: none;
    background: $color-dark-65 url('~images/icon-control-back-to-top-72.svg') no-repeat center;
    z-index: $back-to-top-z;
    &:hover { background-color: $color-dark-80; }
}
