@import '~css/base.scss';

$merchantLabelWidth: pxToRem(90);

.merchant-page-count-dropdowns {
    @include media-breakpoint-only(md) {
        max-width: pxToRem(540);
    }

    @include media-breakpoint-up(lg) {
        .merchant-dropdown {
            label { margin: 0 !important; width: $merchantLabelWidth !important; }
            .dropdown { width: calc(100% - #{$merchantLabelWidth}) !important; }
        }

        .results-dropdown {
            label { width: auto !important; }
            .dropdown { width: pxToRem(90) !important; }
        }
    }
}