@import 'css/base.scss';

.page-section:not(.full-width) {
    max-width: 1477px;

}

.reference-items dt,
.reference-items dd {
    margin-bottom: 0.5rem !important;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

.reference-title {
    padding-bottom: 1.5rem;
}

.reference-items dt,
.reference-items dd {
    margin-bottom: 2rem;
}

.reference-items {
    display: flex;
    flex-direction: column;
}

.edit-description-list:not(.two-column) dt:last-of-type {
    margin-top: 4.5px;
}

.edit-reference-divider {
    margin: 3rem 0 1.5rem;
}

.edit-reference-divider:last-of-type {
    margin: 1rem 0 1.5rem;
}

.edit-description-list dt:first-of-type {
    padding-top: 0.5rem;
}

.reference-edit-button {
    background-color: azure;
}

.set-thresholds-form {
    background-color: #F4F4F4;
    padding: 23px 15px 8px 15px;
    margin-bottom: -0.75rem;

    .row:not(:last-child) {
        margin-bottom: -1rem;
    }
}

.range-input-field-text-from {
    padding-top: 0.75rem;
    margin-right: 12px !important;
}

.range-input-field-text-to {
    padding-top: 0.75rem;
    margin-right: -8px !important;
    margin-left: -16px;
}

.input-label-inline {
    display: flex;
    gap: 0.5rem;
    margin-left: 1rem;

    .label-wrapper {
        margin-top: 0.75rem
    }
}

.delete-customer-reference-button {
    cursor: pointer;
    padding-top: 2rem;
    display: flex;
    justify-content: flex-end;

    img {
        margin-right: 5px;
    }
}

.new-customer-reference-button {

    justify-content: flex-end;
}