@import '~css/base.scss';

.menu-item-expander {
    float: right;
    background: url(~images/arrowdown-white.svg) no-repeat;
    width: pxToRem(20);
    height: pxToRem(10);
    background-size: pxToRem(20) pxToRem(10);
    margin-top: calc(($menu-item-height - pxToRem(10)) / 2);
    margin-right: 12px;

    &.is-expanded {
        transform: rotateX(180deg);
    }
}

.nav-menu-item-content {
    .menu-item-expander {
        background: url(~images/ArrowDown.svg) no-repeat;

        &.active {
            background: url(~images/arrowdown-white.svg) no-repeat;
        }
    }
}