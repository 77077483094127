@import 'css/base.scss';

.accepted-cards-containers {
    .accepted-cards {
        > .header .title-wrapper h2 { margin-bottom: 2.5rem; }

        h3 { margin-top: 0; }

        .card-logo {
            width: pxToRem(32);
            margin-right: pxToRem(12);
        }

        .biller-wallet-logo {
            width: pxToRem(60);
            height: pxToRem(32);
            margin-right: pxToRem(12);
        }
    }
}
