@import 'css/base.scss';

.scrolling-table-narrow {
    .padded-container {
        margin-bottom: 0;

        table {
            @include tableHighlightTotal();
            width: 100%;
        }
    }
}

