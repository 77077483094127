@import 'css/base.scss';

.export-settlements-page {
    .header { 
        p:last-child { margin-bottom: 0; }
    }

    .form-container { margin-top: 1.5rem; }

    .date-range-preset-field { margin-bottom: 0; }

    .export-format { margin-bottom: -1rem; }

    .export-options-desc { margin: 2.5rem 0 1.5rem; }

    .column-checkboxes { margin-bottom: -0.5rem;
        .form-check { margin-right: 0 !important;
            @include media-breakpoint-up(sm) { min-width: 50%; }
            @include media-breakpoint-up(lg) { min-width: 33%; }
        }
    }
}