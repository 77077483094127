@import 'css/base.scss';

.change-password-page {
    .form-header { margin-bottom: pxToRem(40); }

    .password-rules { margin-bottom: pxToRem(40); }
    display: flex;
}

.message-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    width: 100%;
}

.message-item {
    margin: 10px;
}

.icon-alert.icon {
    width: 32px !important;
    height: 32px !important;
    margin: 5px;
}

.loading-indicator-container {
    padding: 0px !important;
    margin: 0px !important;
}
