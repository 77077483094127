@import 'css/base.scss';

.delivery-address-selector { margin: 0 0 1.5rem;

    > .form-group { margin: 0;

        > .label-wrapper > label { font-weight: normal; }

        .selector-group { margin-bottom: 0;
            > .form-check { 
                margin: 0; padding: 1rem !important; background: $color-light-10;

                &:nth-child(even) { background: $color-light; }
            }
        }
    }

    .new-address-form {
        padding: 0 1rem 0 1rem; background: $color-light-10;
        &.white { background: $color-light; }
    }
}
