@import '~css/base.scss';

.form-group > .bank-account-field > .form-group { 
    @include media-breakpoint-up(sm) { margin-bottom: 0; } 
    @include media-breakpoint-down(xs) { margin-bottom: 0.5rem; } 
}

.bank-account-field {
    @include media-breakpoint-up(sm) {
        display: flex;
        .bsb-input {
            width: 25%;
        }
        
        .dash {
            text-align: center;
            padding-top: pxToRem(12);
            width: 5%;
        }
        
        .account-input {
            width: 70%;
        }
    }

    @include media-breakpoint-only(xs) {
        .dash {
            display: none;
        }
    }

    .display-label {
        margin-bottom: pxToRem(8);
    }
}