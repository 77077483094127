@import 'css/base.scss';

.linked-tokens-list {
    margin-bottom: 1.5rem;

    .table:not(.highlight-total) {  // stronger specifity
        @include tableGrid();
    }

    .expiry-warning { 
        .icon-text-container {
            display: inline-flex; 
            margin: 0 2rem 0 0;
            vertical-align: middle;

            @include media-breakpoint-only(xs) { margin-right: 1rem; }
        }

        .update-text { 
            color: $color-link;
            vertical-align: middle;
        }
    }
}
