@import 'css/base.scss';

$black: $color-text;
$yellow: $color-primary;
$lightYellow: #fef6ce; //rgba(255, 204, 0, 0.2); 
$bg-pos-right: pxToRem(11);
$bg-pos-top: pxToRem(11);

input.date-picker {
    @include textbox;
    padding-right: pxToRem(40) - $input-border-width; 
    width: 100%;
    background: $color-light url('~images/icon-calendar.svg') no-repeat scroll calc(100% - #{$bg-pos-right - $input-border-width}) ($bg-pos-top - $input-border-width);
}

.flatpickr-calendar { border-radius: 0; color: $black;
    &:before, &:after { display: none; }

    .flatpickr-months {
        .flatpickr-month { border-radius: 0; background: $color-light-10; color: $black; 
            .flatpickr-current-month select:not(:hover) { background: none; }
            .flatpickr-current-month select > option { background: none; }
        }
        .flatpickr-prev-month, .flatpickr-next-month { color: $black; fill: $black; }
    }

    .flatpickr-weekdays { background: $color-light-10;
        .flatpickr-weekday { background: $color-light-10; color: $black; }
    }

    .flatpickr-days { border-color: $color-light-10;
        .flatpickr-day {
            &.selected, &.startRange, &.endRange { background: $yellow; border-color: $yellow; 
                &.startRange + .endRange:not(:nth-child(7n+1)) { box-shadow: -10px 0 0 $yellow; }
            }
            
            &.inRange { background: $lightYellow; border-color: $lightYellow; box-shadow: -5px 0 0 $lightYellow, 5px 0 0 $lightYellow; }
        }
    }
}