@import 'css/base.scss';

.quantity-controller-wrapper {
    display: flex;
    flex-direction: column;
    
    &.shopping-cart {
        min-width: 9.5rem;
        max-width: 9.5rem;
    }

    .unit-price {
        text-align: center;
        font-size: 1.25rem;
        font-weight: bold;
    }

    .button-wrapper{
        
        .item-cart-button {
            margin-right: 0 !important;
        }

        .success-message {
            text-align: center;
            margin: 0 auto;
            padding: 0;
        }
    }
}
