.additional-payments-empty {
    padding-left: 1rem;
}

.additional-payments-section {
    .switch.small {
        .control-label {
            height: initial;
        }
    }
}

.additional-payments-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.additional-payment-title {
    padding-bottom: 1.5rem;
}

.additional-payments-section dd {
    word-break: break-all;
}
