@import 'css/base.scss';

.icon { display: inline-block; width: 1em; height: 1em; background: transparent no-repeat center; background-size: contain;

    &.icon-question    { background-image: url('~images/HelpIcon.svg'); }
    &.icon-info        { background-image: url('~images/icon-info-16.svg'); }
    &.icon-alert       { background-image: url('~images/Error_InputField.svg'); }
    &.icon-tick        { background-image: url('~images/Tick.svg'); }
    &.icon-check       { background-image: url('~images/checkmark.svg'); }
    &.icon-search      { background-image: url('~images/Search.svg'); }
    &.icon-x           { background-image: url('~images/exit.svg'); }
    &.icon-arrow-left  { background-image: url('~images/BackArrow.svg'); }
    &.icon-user        { background-image: url('~images/LoginIcon.svg'); }
    &.icon-calendar    { background-image: url('~images/pictogram-calendar.svg'); }
    &.icon-lock        { background-image: url('~images/icon-lock.svg'); }
    &.icon-not-found   { background-image: url('~images/icon-not-found.svg'); }
    &.icon-remove      { background-image: url('~images/icon-control-item-remove-24.svg');}
    &.icon-add         { background-image: url('~images/icon-control-item-add-24.svg');}
    &.icon-person      { background-image: url('~images/pictogram-person.svg'); }
    &.icon-plus-circle { background-image: url('~images/pictogram-plus.svg'); }
    &.icon-money       { background-image: url('~images/pictogram-money.svg'); }
    &.icon-card        { background-image: url('~images/pictogram-card.svg'); }
    &.icon-share       { background-image: url('~images/pictogram-share.svg'); }
    &.icon-ideas       { background-image: url('~images/pictogram-ideas.svg'); }
    &.icon-trauma      { background-image: url('~images/pictogram-trauma.svg'); }
    &.icon-switch      { background-image: url('~images/icon-switch-blue.svg'); }
    &.icon-hourglass   { background-image: url('~images/pictogram-term-deposit.svg'); }
    &.icon-desktop     { background-image: url('~images/pictogram-desktop.svg'); }
    &.icon-mobile      { background-image: url('~images/pictogram-mobile.svg'); }
    &.icon-identity    { background-image: url('~images/pictogram-identity.svg'); }
    &.icon-note        { background-image: url('~images/pictogram-note.svg'); }
    &.icon-account     { background-image: url('~images/pictogram-account.svg'); }
    &.icon-delete      { background-image: url('~images/icon-control-delete-24.svg'); }

    &.inline { vertical-align: -5%; margin-right: 0.5em; }

    @media print {
        @include printBackground();
    }

}
