@import 'css/base.scss';

.new-edit-schedule-form {
    h2.details-section-header {
        margin-top: 0;
    }
    
    .add-payment-method-card {
        margin-top: 3rem;
        max-width: 910px;

        @include media-breakpoint-down(sm) {
            margin-left: auto;
            margin-right: auto;
            max-width: 1200px;
        }
        
        img.card-logo {
            width: 2rem;
            @include media-breakpoint-up(lg) { margin-left: 2rem; }
        }
}
}