@import 'css/base.scss';

.preview-tabs {
    .icon-text-container { 
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        color: $color-dark-65; 
        
        .icon { margin-right: 0.5rem; font-size: pxToRem(32); }
        
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
}