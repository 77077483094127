@import 'css/base.scss';

.batch-library-file-page {
    h1 { word-break: break-all;  // H1 is the Filename and it can be long
        &:hover { text-decoration: underline; }
    }

    .btn.right-button {
        @include media-breakpoint-down(md) {
            display: block;
        } 
        @include media-breakpoint-up(lg) {
            float: right; margin: -3.5rem 0 1rem;
        } 
    }
}