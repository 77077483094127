@import 'css/base.scss';

.biller-surcharge-table {
    .card-option {
        display: flex; align-items: center;
        > .card-logo { margin-right: pxToRem(12); width: pxToRem(50); }
        > div { flex-shrink: 0;
            > span { display: block; flex-shrink: 0; }
        }
    }

    th, td {
        &.fixed-amount { width: 12%; }
        &.plus { padding-left: 0 !important; padding-right: 0 !important; }
        &.percent { width: 12%; }
        &.min-amount { width: 14%; }
        &.max-amount { width: 20%; }
        &.example-advanced { max-width: pxToRem(160); }
    }

    .view-example-text { color: $link-color; }

    table.tbl-example {  
        @include tableDefault();
        th { font-weight: bold; }
        tbody tr td { height: 2.5rem; }
    }
}