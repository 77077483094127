@import 'css/base.scss';

.customer-details-page {
    @include media-breakpoint-up(sm) {
        .header-subtitle { max-width: pxToRem(350); }  // Limit the width as the DescriptionList in it would take 100% width and push the header button down
    }

    .payments-section {
        .row.divided {
            h3 { margin-top: 0; }
            .btn-subtle { display: block; }
        }
    }
}
