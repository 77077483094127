.single-column-form-container {
    margin-left: initial;
    margin-right: initial;
    width: 460px;
}

.add-element-panel {
    display: flex;
    gap: 1rem;
}

.add-element-panel .btn {
    margin-bottom: 0;
}

.request-type-form-group {
    margin-bottom: 0 !important;
}

#requestType_info {
    white-space: nowrap;
}
