@import 'css/base.scss';

.biller-details {
    h3 { margin-top: 0; }

    .card-logo {
        width: pxToRem(32);
        margin-right: pxToRem(12);
    }

    .biller-wallet-logo {
        width: pxToRem(60);
        height: pxToRem(32);
        margin-right: pxToRem(12);
    }
}