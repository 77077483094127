@import '~css/base.scss';

.fraud-advanced-section-heading {
    h2 {
        margin: 0;
        line-height: pxToRem(28);
        padding: 0 0 pxToRem(16) 0;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: space-between;
    }
}

.fraud-advanced-error-message {
    color: $color-utility-red;
}

.fraud-advanced-upload-error-filename {
    display: flex;
    justify-content: center;
}

.fraud-advanced-form-page-size-drop-down {
    @include media-breakpoint-up(lg) {
        label {
            width: auto !important;
        }
        .dropdown {
            width: pxToRem(90) !important;
        }
    }
}
