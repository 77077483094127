@import 'css/base.scss';

.confirm-schedule-modal {
    dd {
        text-align: right;
    }

    img.card-logo {
        width: 2rem;
        @include media-breakpoint-up(lg) { margin-left: 2rem; }
    }
}