@import 'css/base.scss';

.custom-reports-wrapper {
    .date-picker-container {
        padding-bottom: 1.5rem;
    }

    tr td:first-child {
        width: 50px;
    }
    
    tr td:last-child {
        width: 200px;
    }   
}
