@import 'css/base.scss';

.customer-payment-methods-section {
    .section-header .btn-primary {
        float: right;
    }

    img.card-logo {
        width: 2rem;
        @include media-breakpoint-up(lg) { margin-left: 2rem; }
    }
}