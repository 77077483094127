@import 'css/base.scss';

.invoice-items {
    @include media-breakpoint-up(lg) {
        // Check
        th:nth-child(1),
        td:nth-child(1) {
            width: pxToRem(51);
        }

        // Unit price
        th:nth-child(3),
        td:nth-child(3) {
            width: pxToRem(144);
        }

        // Quantity
        th:nth-child(4),
        td:nth-child(4) {
            width: pxToRem(94);
        }

        // Tax
        th:nth-child(5),
        td:nth-child(5) {
            width: pxToRem(153);
        }

        // Amount
        th:nth-child(6),
        td:nth-child(6) {
            width: pxToRem(144);
        }
    }
}

.invoice-items__unit-price {
    input {
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
    }
}

.invoice-items__quantity {
    input {
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
    }
}

.invoice-items__amount {
    input {
        @include media-breakpoint-up(lg) {
            text-align: right;
        }
    }
}

.invoice-items__delete-selected {
    display: flex !important;
    align-items: center !important;

    background: transparent !important;
    color: $color-dark-65 !important;
    border: none !important;
    padding-left: 0 !important;

    .icon {
        width: pxToRem(24);
        height: pxToRem(24);
    }
}

.invoice-items__max-items {
    margin-top: pxToRem(17);

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.invoice-items__totals {
    text-align: right;
    font-size: pxToRem(20);
    line-height: pxToRem(28);

    // Space between different totals
    > div {
        margin-bottom: pxToRem(8);

        :last-child {
            margin-bottom: 0;
        }
    }

    // When items are displayed as a table
    @include media-breakpoint-up(lg) {
        margin-right: pxToRem(12);

        .invoice-items__total-label {
            display: inline-block;
            text-align: right;

            margin-right: pxToRem(16);
        }

        .invoice-items__total-value {
            display: inline-block;
            width: pxToRem(174);
        }
    }

    // When items are displayed as cards
    @include media-breakpoint-down(md) {
        margin-top: pxToRem(17);

        > div {
            display: flex;
            justify-content: space-between;
        }

        .invoice-items__toal-label {
            text-align: left;
        }
    }
}
