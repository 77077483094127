@import '~css/base.scss';
@import '~css/styles/layout.scss';

.login-page {
    .forgot-password-md { margin: 1rem 0; text-align: right; }
    .switch-to-old-url-md { margin: 1rem 0; text-align: left; }
    @include media-breakpoint-down(sm) {
        .login-form-container h2 { @include text-base; }
    }

    .login-form {
        margin-top: 30px;
    }

    .login-password-input.form-group {
        display: flex;
    }

    @include media-breakpoint-up(md) {
        .login-form {
            margin-top: 50px;
            margin-bottom: 20px;
        }
    }
}
