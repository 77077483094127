@import 'css/base.scss';

.set-visa-configuration {
    padding-top: 30px;
}

.set-transaction-threshold {
    background-color: #F4F4F4;
    padding: 23px 15px 8px 15px;
    border-radius: 5px;

    .currency-configuration {
        display: inline-flex;

        .currency-label {
            align-self: center;
            margin-right: 20px;
        }

        &:has(.error) .currency-label {
            margin-bottom: 42px;
            align-self: center;
            margin-right: 20px;
        }

        .currency-field {
            margin: auto;
        }
    }
}

.disclaimer-section {
    background-color: white;
    border-radius: 5px;
    padding: 23px 15px 8px 15px;
    border: 1px solid #F4F4F4;
    font-size: 0.875rem;
    font-weight: 400;
}