@import 'css/base.scss';
.quantity-title {
    text-align: right;
}

.quantity-value-controller {
    .form-group {
        display: flex;
        flex-direction: column;
        max-width: 180px;
        
        .quantity-controls{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            button.left, button.right {
                &:disabled {
                    opacity: 0.5;
                }
            }
    
            input {
                padding: 0px !important;
                text-align: center;
                width: 3rem;
                height: 3rem;
            }
        }

        .label-wrapper {
            label {
                margin-right: auto;
                margin-left: auto;
                font-weight: normal;
            }
        }
        
        .error {
            align-items: center;
            text-align: center;
            margin: .5rem ;
            margin-left: auto ;
            margin-bottom: auto;
            margin-right: auto;
        }
    }   
}
