@import 'css/base.scss';

.file-upload-field-actions {
    display: flex;
    column-gap: 1rem;
}

.file-upload-field {
    .file-info {
        .icon {
            margin-right: 0.5rem;
            vertical-align: pxToRem(-2);
        }
    }
}

.file-upload-field-button-container {
    .delete-button {
        margin-left: 1.3rem;
    }
}