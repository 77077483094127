@import 'css/base.scss';

.file-upload-field-actions {
    display: flex;
    column-gap: 1rem;

    .file-info {
        display: flex;
        align-items: center;

        .icon {
            margin-right: 0.5rem;
            vertical-align: pxToRem(-2);
        }
    }
}

.file-upload-field-button-container {
    text-align: right;
}