@import '~css/base.scss';


$default-background-color: $color-light;

$header-btn-size: pxToRem(20);
$header-btn-size-large: pxToRem(40);

header {
    flex:none;
    width: 100%;
    z-index: $header-z;
    height: $header-height;
    background: $default-background-color;
    box-shadow: $card-box-shadow;

    $header-btn-size: pxToRem(24);
    $header-btn-size-large: pxToRem(40);

    @mixin set-header-btn-size($size) {
        width: $size;
        height: $size;
        top: calc(($header-height - $size) / 2);
    }

    @include media-breakpoint-down(sm) {
        position:fixed;
        top: 0;
    }

    .menu-toggle-button {
        @include set-header-btn-size($header-btn-size);

        &.menu-left {
            position: absolute;
            left: $header-padding-left;
        }

        &.menu-right {
            margin-right: $header-padding-left;
        }

        &.profile-image {
            @include set-header-btn-size($header-btn-size-large);
        }
    }
}

.header-logo {
    width: $header-logo-width;
    height: $header-logo-height;
    margin: 0 auto;
    padding-top: $header-logo-padding-top;
    /*line-height: $header-height;*/
    .img {
        width: $header-logo-width;
        height: $header-logo-height;
    }
}

.right-menu-options {
    display: inline;
    position: absolute;
    right: 20px;
    top: 0;
    z-index: $right-menu-z;
}

.public-navigation-items {
    display:none;
    list-style: none;
    margin-top: 20px;
    float:left;
}

.user-navigation-container{
    float:left;
    width:75px;
    margin-top:12px;
}

.public-navigation-item {
    display: inline-block;
    margin-left: 32px;
}

.public-navigation-item { 
    a:link, a:hover, a:visited, a:active {
        color: $color-dark-80;
        text-decoration:none;
    }
}


@include media-breakpoint-up(md) {
    .menu-toggle-small {
        display: none;
    }  

    .header-logo {
        margin-left: $header-padding-left;
    }

    .public-navigation-items {
        display: block;
    }

    .header-nav-menu {
        display: none;
    }
}