@import 'css/base.scss';

.shop-item-container {
    display: flex;  
    flex-direction: row;
    flex-wrap: wrap;
    
    @include media-breakpoint-between(sm, md) {
        .quantity-controller{
            padding-top: 1rem;
            flex: 0 1 100%;
            
            .quantity-controller-wrapper {
                flex-direction: row;
                align-items: center;
                
                .quantity-value-controller {
                    width: 16rem;
                    padding-left: 2.25rem;
                    padding-right: 2.25rem;
                }

                .item-cart-button {
                    margin-left: 1.5rem;
                }
            }
        }
    }
    
    @include media-breakpoint-down(xs) {
        flex-direction: column;

        .item-image {
            margin: 0 auto;
            margin-bottom: 1rem;
        }
        
        .quantity-controller{ 
            padding-top: 1.5rem;
            margin: 0 auto;
        }
    }
    
    .item-image {
        min-width: 16rem;
        max-width: 16rem;
        min-height: 16rem;
        max-height: 16rem;
        background-color: #FFFFFF;
        justify-content: flex-start;
        flex: 1 0 25%;
        text-align: center;
    }
    
    .item-description {
        flex: 1 0 25%;
        padding-left: 1.5rem;
        padding-right: 1rem;

        .product-name-and-code {
            font-weight: bold;
            font-size: 16px;
            padding-bottom: .5rem;
        }

        .product-cost {
            font-weight: bold;
            font-size: 20px;
            padding-bottom: 1rem;
        }
    }
}