@import 'css/base.scss';

.hpp-preview {
    .page {
        padding: 50px;
        background: #e1e1e0;

        .content {
            padding: 25px;
            min-height: 400px;
            max-width: 1170px;
            background: white;

            dl {
                dt { margin-bottom: 5px }
                dd {
                    &:not(.no-pad) { padding: 7px 0; }
                }
            }


            input[type=text] {
                padding: 6px 12px;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                width: 100%;
                height: 34px;
            }

            .fake-button {
                padding: 6px 0;
                border-radius: 2em;
                background: #ffcc00;
                text-align: center;
            }

        }
    }

    .top {
        line-height: 20px;
        text-align: center;
        border-style: solid;

        .navbar-gap {
            margin-bottom: 20px;
        }

        span {
            font-size: 11px;
            margin-right: 25px;
            &:last-child { margin-right: 0; }
        }
    }
}

.mobile .hpp-preview {
    .page { padding: 50px 20px; }
}