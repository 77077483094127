@import "~css/base.scss";

.form-check-input[type='checkbox'] {
    @include selector();

    $tick-size: pxToRem(13);

    + label { 
        /* The check mark */
        &:after {
            background-image: url('~images/checkmark.svg'); 
            background-repeat: no-repeat; 
            background-position: center; 
            background-size: $tick-size $tick-size;
        }
    }

    /* Check mark visibility */
    &:checked {
        + label:before { background: $color-dark-80; border-color: $color-dark-80; }
        + label:after { color: $color-light; }
    }

    /* Disabled style */
    &:disabled {
        + label:before { background: $color-light-20; border-color: $color-light-20; }
    }
}
