@import 'css/base.scss';

.accordion-card {
    padding: pxToRem(26);
    margin-bottom: pxToRem(16);
    border-radius: 5px;
    background-color: white;

    &.togglable {
        .accordion-card-header {
            cursor: pointer;
        }

        .accordion-card-title {
            &:after {
                @include pseudo(inline-block, static);
                margin-left: pxToRem(16); width: pxToRem(16); height: pxToRem(16);
                background: url('~images/ArrowDown.svg') no-repeat center;
                @include rotate(0);
                transition: .15s;
            }
        }
    }

    .accordion-card-header {
        .icon {
            width: pxToRem(48);
            height: pxToRem(48);
        }
    }

    .accordion-card-title {
        @include text-large3-emphasis;
    }

    @include media-breakpoint-up(sm) {
        .accordion-card-title-line {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .accordion-card-icon {
            margin-right: pxToRem(8);
        }
    }

    .accordion-card-description {
        margin-top: pxToRem(8);
    }

    .accordion-card-content {
        display: none;
    }

    &.expanded {
        .accordion-card-header {
            margin-bottom: pxToRem(16);
        }
        .accordion-card-content {
            display: block;
        }

        .accordion-card-title:after { @include rotate(-180); }
    }

}
