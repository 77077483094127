@import 'css/base.scss';

@mixin loadingOverlay() {
    position: absolute; left: 0; top: 0; width: 100%; height: 100%;  // covers the element
    background: rgba(0,0,0,0.5);
    z-index: $loading-overlay-z;

    > div {
        position: absolute; top: 50%; transform: translateY(-50%);  // vertically center
        width: 100%; text-align: center;  // horizontally center
        color: $color-light;
    }
}

.loading-overlay-page { @include loadingOverlay(); position: fixed; }  // covers the whole page
