﻿@import 'css/constants/mixins.scss';

// CBA colors
$color-commbank-yellow: #ffcc00; // primary
$color-commbank-black: #231f20;  // dark
//$color-transparency-dark: rgba(0, 0, 0, 0.6);
$color-dark-80: #4f4c4d;
$color-dark-65: #706d6e;
$color-light: #ffffff;         // white
$color-light-05: #f4f4f4;
$color-light-20: #d3d2d2;
$color-utility-blue: #1175b5;  //info
$color-utility-red: #e1001a;   //danger
$color-utility-green: #00aa00; //success / switch-on
$color-shadow-2: rgba(35, 31, 32, 0.08); // for card shadow
$color-light-10: #e9e9e9; // for hovered/disabled button/input, and backgrounds
$color-light-gold: #ffdb4d; // for button hover (yellow)
$color-burnt-yellow: #d3a906; // for button pressed (yellow)

// Undefined CBA colors
$color-unnamed-grey: #bdbbbc; // for button pressed (grey)
$color-unnamed-light: #fafbfc; // for gradient/background

// Shadows
$shadow-blur: pxToRem(16);
$card-box-shadow: 0 0 $shadow-blur 0 $color-shadow-2;


// Common colors
$color-primary: $color-commbank-yellow;
$color-primary-hover: $color-light-gold;
$color-primary-active: $color-burnt-yellow;
$color-focus: $color-utility-blue; 

$color-text: $color-commbank-black;
$color-link: $color-utility-blue;
$color-error: $color-utility-red;
