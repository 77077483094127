@import 'css/base.scss';

.range-field {
    .field-wrapper {
        display: flex; align-items: center;
        .separator { margin: 0 pxToRem(6); }

        @include media-breakpoint-down(xs) {
            display: block;
            .separator { display: block; margin: pxToRem(8) 0; }
        }
    }
}
