@import '~css/base.scss';

.help-text-container { 
    margin-top: pxToRem(24px);

    > h3 {
        margin-bottom: 0.25em;
        
        @include media-breakpoint-up(md) { 
            @include text-large1;
            font-weight: normal; 
        }
    }
}
