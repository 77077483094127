@import '~css/base.scss';

.page-header-container {
    @include media-breakpoint-down(xs) {
        margin-bottom: 2rem;
        &:last-child { margin-bottom: 1rem; }
    }
    @include media-breakpoint-up(sm) {
        &:not(:last-child) { margin-bottom: 1rem; } // There will be 1rem bottom margin from H1/subtitle/buttons because of display:flex, so the total is still 2rem
    }

    > .back-button-container {
        margin-bottom: 1rem;

        @include media-breakpoint-up(sm) { height: pxToRem(48);  }

        > .back-button { margin: 0; }
    }

    .page-header {
        .header-left {
            &.has-subtitle h1 {
                margin-bottom: pxToRem(12);

                .icon.inline {
                    height: 1.2em; // Matches icons height to the h1 line-height to keep it vertically centered
                }
            }

            .header-subtitle {
                .description-list {
                    @include media-breakpoint-up(sm) {
                        dt, dd { margin-bottom: pxToRem(4); }
                    }
                    @include media-breakpoint-down(xs) {
                        dd { margin-bottom: pxToRem(8); }
                    }
                }
            }
        }

        @include media-breakpoint-down(xs) {
            display: block;

            .header-left {
                .header-icon { margin-bottom: 16px; text-align: center;
                    .icon { width: 56px; height: 56px; }
                }
            }

            .header-right { white-space: normal; }
        }

        @include media-breakpoint-up(sm) {
            display: flex; justify-content: space-between; flex-wrap: wrap;

            .header-left {
                display: flex;

                .header-icon {
                    .icon {
                        margin: pxToRem(3) 1rem 0 0;
                        width: 1.5rem; height: 1.5rem;
                    }
                }
            }

            .header-right { white-space: nowrap; }

            // For the gap between header-left & header-right
            $item-margins: 16px;
            margin-left: -1 * $item-margins;
            margin-right: -1 * $item-margins;

            .header-left, .header-right {
                margin-left: $item-margins;
                margin-right: $item-margins;
            }
        }

        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;

            .header-left { flex-shrink: 2; }
        }
    }
}

.public-body-container .page-header-container {
    .back-button-container { display: none; }

    .page-header { margin-bottom: 2.5rem;
        display: block;

        .header-left { display: block;
            .header-icon { display: block; text-align: center;
                .icon { margin: 0; width: 56px; height: 56px; }
            }
        }

        .header-right { white-space: normal; }
    }
}
