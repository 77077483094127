@import 'css/base.scss';

.settlement-reports-page {

    .header-section { padding-bottom: 0.5rem; }

    .date-range-preset-field .date-radios { 
        @include media-breakpoint-up(lg) { padding-top: pxToRem(24); }
    }

    .cards dd { word-break: normal; }
    
}