@import 'css/base.scss';

$roundBadgeHeight: 1.5em;

.badge {
    display: inline-block;
    padding: 0.15em 1em;
    border-radius: 1em;
    background: $color-utility-blue; color: $color-light;
    text-align: center; white-space: nowrap;
    font-size: pxToRem(14);
    font-weight: normal;
    
    &:empty { display: none; }

    &.round {
        padding: 0 0.5em;
        border-radius: calc($roundBadgeHeight / 2);
        min-width: $roundBadgeHeight;
        line-height: $roundBadgeHeight;
        font-size: 1em; 
    }
}