@import '~css/base.scss';
@import '~css/styles/layout.scss';

.contact-context {
    margin-top: -1.5rem;
    margin-bottom: -1rem;
}

.switch-to-old-url-md {
    margin: 1rem 0;
    text-align: left;
}