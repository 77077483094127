.table-no-label {
    .label-wrapper {
        display:none !important; 
    }

    .error {
        display:none !important;
    }

    .form-group {
        margin-bottom: 0 !important;
    }
}