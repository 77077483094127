.three-dot-menu-button.btn.btn-primary,
.show > .three-dot-menu-button.btn-primary.dropdown-toggle {
    background: none;
    border: none;
    min-width: auto;
    color: #000;
    padding: 0;
    margin: 0;
}

.three-dot-menu-button.btn.btn-primary:not(:disabled):active,
.show > .three-dot-menu-button.btn.btn-primary.dropdown-toggle:focus {
    background: none;
    box-shadow: none;
    color: #000;
    padding: 0;
    margin: 0;
}

.three-dot-menu-button.btn.btn-primary.dropdown-toggle::after {
    content: none;
}

.three-dot-menu-dropdown .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;

    a:hover,
    .btn-link:hover {
        background-color: transparent;
        text-decoration: none;
    }
}
