.request-pending-dialog {
    .request-pending-timer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon-hourglass {
            width: 3rem;
            height: 3rem;
            margin-bottom: 0.5rem;
        }

        .loading-indicator-container {
            margin-bottom: 1rem;
        }
    }

    .timer-part {
        display: inline-block;
        min-width: 2ch;

        &:first-of-type {
            text-align: right;
        }
    }
}