@import '~css/constants/colors.scss';

html {
  //fixes the scroll bar so that it doesn't shift the content.
  overflow-y: overlay;
  overflow-x: overlay;
}

.app {
  display:flex;
  flex-direction:column;
}

.app-container {
  min-height: 100%;
  position: relative;
  height:100%;
}


.app.print { background: none; 
    header, footer, .nav-container { display: none; }
    .menu-body-wrapper { margin-top: 0; }
    .body-container { padding: 20px; background: none; }
}