.password-field-hide-show {

    display: flex;
    justify-content: space-between;
    align-items: center;

    // Keeps the outline consistent on hover for both input and appended component.
    input.textbox.has-error {
        border-right: none;
    }

    .input-group-append {
        .has-error {
            border-left: none;
        }
    }

    .form-group {
        width: 100%;
    }

    .reveal-icon {
        cursor: pointer;
    }

    .invalid-feedback {
        display: inline-block !important;
        font-size: 1rem;
        margin: 0.5rem 0;
        background: url('~images/Error_InputField.svg') no-repeat left 0;
        background-size: 1rem 1.5rem;
        padding-left: 1.375rem;
        color: #e1001a;
    }
}

//  Keep both label and input in vertical alignment as the error message becomes visible. 
.password-field-hide-show.has-error {
    align-items: flex-start;

    // Whilst form group requires a name property, the new feature no longer requires this message. 
    .error {
        display: none;
    }
}