@import 'css/base.scss';

.stationery-home-page {

    @include media-breakpoint-down(xs) {
        .stationery-header {
            > a { margin-bottom: 2rem; }
        }
    }

    @include media-breakpoint-up(sm) {
        .stationery-header {
            position: relative;
    
            > a {
                position: absolute; top: 56px; right: 0;
                margin: 0;
            }
        }
    
        .accordion {
            display: flex; flex-direction: row; align-items: baseline;
            
            .accordion-title { min-width: 120px; }
        }
    }
}