@import 'css/base.scss';

.warning-icon-text {
    display: flex;
    align-items: center;

    .icon-text-contents {
        color: #ffcc00;
        margin-left: 0.3rem;
        margin-top: 0.3rem;
    }

}