@import '~css/base.scss';

.messaging-config-options {
    display: block;

    > .label {
        font-weight: bold;
        margin-bottom: 1.5em;
    }

    > .content {
        margin-bottom: 1.5em;
        display: flex;

        > * {
            &:not(:last-child) { margin-right: 3em; }
        }

        > .form-check .form-check-label{
            display: flex;
            }
    }

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;

        > .label {
            flex: 0 0 15em;
        }

        > .content {
            flex: 1 1 auto;
            flex-wrap: wrap;

            > * {
                flex: 0 1 auto;
                &:not(:last-child) { margin-right: 3em; }
            }
        }
    }
}