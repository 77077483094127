@import 'css/base.scss';

.batch-jobs {
    .tab-radio-field {  // Note: Create a component called TabRadioField if this ever needs to be reused
        .selector-group { 
            display: flex; 
            border-bottom: 4px solid $color-light-20;

            .form-check {
                flex-grow: 1;
                text-align: center;
                margin-bottom: -6px;

                label {
                    padding: 8px 0;
                    width: 100%;

                    &:before, &:after { display: none; }
                    &:hover { text-decoration: underline; }
                }

                input:checked + label { 
                    border-bottom: 8px solid $color-primary;
                    font-weight: bold;
                }
            }
        }
    }

    .tab-radio-field {  // Probably specific to this page (while the above can be moved to a common component)
        label .icon { position: relative; top: -5px; left: 3px; }
    }

    table tr.loading { display: none; }  // Temporarily hide loading indicator as it becomes annoying when auto-refreshing
}