@import '~css/base.scss';

.body-container {
    background-color: $color-light-05;
    padding: 32px;
}

@include media-breakpoint-up(md) {
    .body-container {
        padding: 16px 48px 48px;
    }
}
