@import '~css/base.scss';

.floating-container {
    @include media-breakpoint-up(md) {
        //https://www.sitepoint.com/collapsing-margins/
        padding-top: 1px; //This breaks the collapsing margins.
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
        padding: 0;
    }

    .floating-panel-container {
        padding: 30px;
        background: $color-light-05;
    
        h1 { @include text-large3-emphasis; }
        h2 { @include text-base; font-weight: normal; }
        h3 { @include text-base-emphasis; }
    
        @include media-breakpoint-up(md) {
            width: 608px;
            margin: 0 auto 0 auto;
            background: rgba($color-light-05, 0.95);
            padding: 64px;
    
            h1 { @include text-hero; font-weight: normal; }
            h2 { @include text-large1; font-weight: normal; }
            h3 { @include text-base-emphasis; }
        }
    
        @include media-breakpoint-down(sm) {
            margin-left: auto;
            margin-right: auto;
            max-width: 600px;
        }
    }
}