@import 'css/base.scss';

.receipt-preview {
    .body { 
        padding: 25px 50px;
        background: #f3f4f5;

        .body-inner {
            padding: 40px 40px 24px;
            max-width: 1170px;
            background: white;

            .content {
                margin: 0 auto;
                max-width: 768px;

                .heading { margin-bottom: 24px;
                    .icon { margin-bottom: 8px; font-size: 36px; }
                    .title { font-size: 22px; font-weight: bold; }
                    .amount { line-height: 2em; font-size: 28px; font-weight: bold; }
                }

                dt { margin-bottom: 4px; }

                .row + .divider { margin-top: 8px; }
            }
        }
    }
}

.mobile .receipt-preview {
    .body { 
        border-top: 2px solid #ccc;
        border-bottom: 1px solid #eee;
        padding: 0; 
        
        .content {
            .heading { text-align: center; }
        }
    }
}