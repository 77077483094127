@import '~css/base.scss';

.form-check-input[type='radio'] {
    @include selector(pxToRem(2));

    $dot-size: pxToRem(12);
    $base-color: $color-commbank-black; //$color-dark-65;

    + label {
        /* The radio button */
        &:before {
            border-color: $base-color;
            border-radius: 100%;
        }

        /* The dot */
        &:after {
            top: calc(($chk-size - $dot-size) / 2);
            left: calc(($chk-size - $dot-size) / 2);
            width: $dot-size;
            height: $dot-size;
            border-radius: 100%;
            background: $base-color;
        }
    }

    /* Disabled style */
    &:disabled {
        + label {
            &:before { border-color: $color-dark-65; }
            &:after { background: $color-dark-65; }
        }
    }
}
