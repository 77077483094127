@import 'css/base.scss';

.pagination-dots {
    margin: 0.5rem 0;
    display: flex; justify-content: space-between;

    .prev-arrow, .next-arrow {
        padding: 1rem;line-height: 0;
        cursor: pointer;

        &.disabled { opacity: 0.3; cursor: not-allowed; }

        > div {
            width: 1rem; height: 1rem; 
            background: url(~images/ArrowDown.svg) no-repeat center;
        }
    }
    .prev-arrow { @include rotate(90); }
    .next-arrow { @include rotate(270); }

    ul.dots {
        margin: 0; padding: 0; list-style: none;
        display: flex;

        li {
            margin: 0 pxToRem(4);
            padding: 1rem pxToRem(8);
            cursor: pointer;

            > div { @include dot(1rem, $color-light-20); }
            &.active > div { background-color: $color-dark-65; }
        }
    }
}
