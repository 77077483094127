@import '~css/base.scss';
@import '~css/constants/colors.scss';

.overdue-alert {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .icon-text-container {
        margin-bottom: 0;
        margin-right: 1em;
    }
}