@import 'css/base.scss';

.invoice-form__tooltip-icon {
    height: pxToRem(20);
    width: pxToRem(20);
}

.invoice-form__notes-char-remaining {
    margin-top: pxToRem(4);
}

.invoice-form__send-button {
    margin-top: pxToRem(14) !important;
}

.invoice-form__submit-error {
    color: $color-utility-red;
}

.invoice-form__footer {
    margin-top: pxToRem(16);
}
