@import '~css/base.scss';

.fraud-rule-toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: pxToRem(8) 0;
    min-height: pxToRem(70);
}

.fraud-rule-toggle-title {
    font-weight: 700;
    font-size: 1.25rem;
}

.fraud-rule-toggle-subtitle {
    margin-right: 5px;
}

.fraud-rule-toggle-button-wrapper-small {
    @include media-breakpoint-up(sm) {
        display: none;
    }

    margin: pxToRem(8) 0;
}

.fraud-rule-toggle-button-wrapper-large {
    .switch .control-label {
        display: inline;
    }
    @include media-breakpoint-down(xs) {
        display: none;
    }
}