﻿// @import "node_modules/bootstrap/scss/functions";
// @import "node_modules/bootstrap/scss/mixins";


$baseFontSize : 16;
@function pxToRem($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return calc($pixels / $context) * 1rem;
}

@mixin cardDropShadow() {
  box-shadow: $card-box-shadow;
}


//#region ----- Cross-browser helper -----

@mixin vendorPrefix($property, $value, $vendors: webkit moz ms o) {
  @if $vendors {
    @each $vendor in $vendors {
      #{"-" + $vendor + "-" + $property}: #{$value};
    }
  }
  #{$property}: #{$value};
}

@mixin transform($transforms) {
    @include vendorPrefix(transform, $transforms);
}

@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); //IE8
}

@mixin placeholder {
  &::placeholder { @content; }
  //&.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} { @content; }
  @-moz-keyframes #{$name} { @content; }
  @keyframes #{$name} { @content; }
}

//#endregion


//#region ----- Common mixins to save typing code -----

/** To be used in ::before and ::after */
@mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

/** To truncate text with ellipsis at the end */
@mixin ellipsis($maxWidth: 100%) {
  max-width: $maxWidth;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/** Render as a filled circle */
@mixin dot($size: 1rem, $color: $color-text) {
    height: $size; width: $size;
    border-radius: 50%;
    background-color: $color;
}

@mixin linkStyle() {
  color: $color-link;
  
  &:not(.disabled) {
      &:active { color: $color-text; text-decoration: underline; }
      &:not(.btn):focus { outline: 2px solid $color-focus; }
  }

  &.disabled, &:not([href]):not([tabindex]).disabled { color: $color-dark-65; cursor: inherit;
      &:hover { text-decoration: none; }
  }
}

// @mixin verticalCenter {
//   position: relative;
//   top: 50%;
//   @include transform(translateY(-50%));
// }

@mixin stackCenter() {
  justify-content: center;
  display: flex;
  flex-direction: column;

  > *, .btn {
      margin-left: auto;
      margin-right: auto;

      &.btn {
        margin-left: auto;
        margin-right: auto;
      }
  }
}

@mixin printBackground() {
    -webkit-print-color-adjust: exact;   // Chrome, Safari
    print-color-adjust: exact;           // Firefox (not fully supported)
}

// Currently used for Modals to justify the button widths
@mixin justifyButtons() {
    width: calc(100% + 2rem);
    margin-left: -1rem; margin-right: -1rem;
    display: flex; justify-content: center; flex-wrap: wrap;

    .btn { flex: 1 1 0px; margin-left: 1rem; margin-right: 1rem; }

    .btn-subtle { text-align: center; }
}
//#endregion