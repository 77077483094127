@import 'css/base.scss';

.loading-indicator-container {
    &.inline { 
        display: inline-block;  
        margin-bottom: 1rem;  // to be the same as Button
        vertical-align: middle; 
    }
    &.hidden { display: none; }
    &.horizontal div {
      width: 18px;
      height: 18px;
      margin-right: 0.5rem;
      border-radius: 100%;
      display: inline-block;
      animation: loading-indicator-slide 1.4s infinite ease-in-out both;

      &:last-of-type {
        margin-right: 0;
      }
      &.indicator-1 {
        animation-delay: -0.5s;
      }
      &.indicator-2 {
        animation-delay: -0.25s;
      }
    }
}

.loading-indicator {
    display: block;
    margin: 0 auto;
    animation: loading-indicator-spin infinite 1s linear;
    height: 40px;
  }

@include keyframes(loading-indicator-spin) {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
}

@include keyframes(loading-indicator-slide) {
  0%, 80%, 100% {
    background: $color-primary;
    transform: scale(0.2);
  }
  40% {    
    background: $color-primary;
    transform: scale(1.0);
  }
}