.icon {
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 3px;
}
.email-verified {
    display: flex;
  }

.icon-button{
    margin-top: -10px;
}