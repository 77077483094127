@use "sass:map";
@import 'css/base.scss';

$sizes: (
    small: pxToRem(18),
    normal: pxToRem(32),
);

$widths: (
    small: pxToRem(32),
    normal: pxToRem(56),
);

.switch {
    .control {
        position: relative;
        width: map.get($widths, "normal");
        height: map.get($sizes, "normal");
        border-radius: map.get($sizes, "normal");
        background-color: $color-light-10;
        cursor: pointer;
        transition: background-color 0.15s ease-out;

        &:hover, &:focus {
            outline: none;
            box-shadow: 0 0 0 2px $color-utility-blue;
        }

        .button {
            position: absolute; top: 0; left: 0;
            width: map.get($sizes, "normal");
            height: map.get($sizes, "normal");
            border: 2px solid $color-light-20;
            border-radius: map.get($sizes, "normal");
            background-color: $color-light;
            transition: all 0.15s ease-out;
        }
    }

    &.on {
        .control { background-color: $color-utility-green;

            .button {
                left: map.get($widths, "normal") - map.get($sizes, "normal");
                border-color: $color-utility-green;
            }
        }
    }

    &.disabled {
        .control {
            cursor: inherit;
            background-color: $color-light-10;

            &:hover, &:focus {
                outline: inherit;
                box-shadow: none;
            }

            .button {
                border-color: $color-light-20;
                background-color: $color-dark-65;
            }
        }
    }

    &.noHover {
        .control {
            cursor: inherit;

            &:hover, &:focus {
                outline: inherit;
                box-shadow: none;
            }
        }
    }

    &.no-bottom-margin {
        margin-bottom: 0 !important;
    }

    .control-label {
        display: inline-block;
        background-position: center;
        height: map.get($sizes, "normal");
        padding-left: 0.5rem;
        vertical-align: middle;
    }
}

.switch.small {
    .control {
        width: map.get($widths, "small");
        height: map.get($sizes, "small");
        border-radius: map.get($sizes, "small");

        .button {
            width: map.get($sizes, "small");
            height: map.get($sizes, "small");
            border-radius: map.get($sizes, "small");
        }
    }

    &.on {
        .button {
            left: map.get($widths, "small") - map.get($sizes, "small");
        }
    }

    .control-label {
        height: map.get($sizes, "small");
    }
}
