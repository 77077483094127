@import 'css/base.scss';

.scrolling-table-wide {
    .padded-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        table {
            @include tableHighlightTotal();
            min-width: 100%;

            th, td { 
                &:first-child {
                    position: sticky; left: 0;
                    
                    &[colspan='1'], &:not([colspan]) { 
                        box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.1);
                        z-index: 2;
                    }
                }
            }

            .sr-only { display: none; }
        }
    }
}
