@import 'css/base.scss';

dl.message-details {

    // 2nd-level
    dl dt { font-weight: normal; }

    @include media-breakpoint-down(md) {
        dt { margin: pxToRem(36) 0 pxToRem(10); }

        // 2nd-level
        dl dt { margin: pxToRem(24) 0 0; }
    }

    @include media-breakpoint-up(lg) {

        &, dl { display: flex; flex-wrap: wrap; }

        $width1: 212;
        $width2: 84;

        > dt, > dd { margin-top: pxToRem(36); }

        dt { width: pxToRem($width1); }
        dd { margin-left: auto; width: calc(100% - #{pxToRem($width1)}); }

        // 2nd-level
        dl {
            > dt, > dd { margin: 0 0 pxToRem(24); }

            dt { width: pxToRem($width2); }
            dd { width: calc(100% - #{pxToRem($width2)}); }
        }
    }
}