.subscription-suspend-dialog {
    .modal-body {
        form {
            margin-top: 1.5rem;
        }

        .form-check-input[type='radio'] + label {
            display: flex;
            align-items: center;

            &:before, &:after {
                top: initial;
            }

            .number-of-payments {
                margin-bottom: 0;
                margin: 0 0.7rem 0 0.7rem;
            }
            .end-date {
                margin-bottom: 0;
                margin: 0 0.7rem 0 0.7rem;
            }
        }
    }
}