@import 'css/base.scss';

.onboarding-page-section {
    .padded-container { 
        .icon { 
            width: pxToRem(64); height: pxToRem(64);
            background-position: left center;
        }

        h2 { margin-top: 0.5rem; }
    }
}