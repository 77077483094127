.hpp-preview-container {
    margin-bottom: 20px;

    .eforms-content:hover .eform-ctrl-container {
        opacity: 0.2;
    }

    .eforms-content .eform-ctrl-container:hover {
        opacity: 1;
    }

    .eforms-content .eform-ctrl-container:hover .control-draggable-icon {
        display: block;
    }
}

.eform-ctrl-actions {
    display: flex;
    gap: 20px;
}

.control-draggable-icon {
    float: right;
    display: none;
    padding: 10px;
}

.qr-code-container {
    text-align: center;
}

.visibility-form {
    padding-top: 20px;
}
