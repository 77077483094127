@import 'css/base.scss';

.payment-plan-list {

    th, td {
        overflow-wrap: anywhere;
    }

    .status-property {
        min-width: 100px;
    }

    td {
        padding: 0.5rem 0.75rem !important;
    }
}
