@import "css/base.scss";

.eform-help {
  display: inline-flex !important;
  vertical-align: text-bottom !important;
  margin-left: 10px;
}

.eform-help {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  top: -100%;
  left: 150%;
  z-index: 1;
  white-space: nowrap;
}

.eform-help:hover .tooltip {
  display: block;
}