@import 'css/base.scss';

.edit-branding-page {
    .padded-container.preview {
        @include media-breakpoint-up(lg) { height: 100%; }
    }

    .divider + h3 { margin-top: 1.5rem; }

    .button-container { margin-top: 0; 
        @include media-breakpoint-up(lg) {
            .btn { width: 100%; }
        }
    }
}