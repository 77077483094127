.print-container {
    font: 13px Verdana, sans-serif;
    color: black;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 0 15px;
        border-bottom: 1px solid black;
        align-items: center;

        h1 {
            margin: 0 0 7px;
            font-size: 16px;
        }

        .print-button {
            @media print { display: none; }

            margin: 0;
            font-size: 11px;
            font-weight: bold;
        }

        .print-button span {
            font-size: 11px;
            font-weight: bold;
        }
    }

    .payment-details {
        margin: 0 0 15px;
        line-height: 20px;
    }
    .receipt-message {
        margin: 15px 0 0;
        padding: 10px 0 0;
        border-top: 1px solid #999;

    }
    .footer {
        margin: 15px 0 0;
        padding: 10px 0 0;
        border-top: 1px solid black;
        font-size: 10px;
    }
}
