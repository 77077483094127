@import "css/base.scss";

.eform-item-link {
    color: #000;

    &:link,
    &:visited,
    &:active,
    &:hover {
        color: #000;
        text-decoration: none !important;
    }

    &:hover > .padded-container {
        @include cardDropShadow();
    }
}

.eform-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        word-break: break-word;
    }
}

.eform-item-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.lock-icon-container {
    display: flex;
    gap: 0.5rem;
}
