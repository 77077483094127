@import '~css/base.scss';

.menu-right {
    margin-left:32px;
}

.exit-button-right {
    margin-top: 6px;
    margin-left:40px;
}

.profile-background-circle {
    background: $color-primary;
    border-radius: 100%;
    width:100%;
    height:100%;
    padding-top: 9px;
}

.profile-image-placeholder {
    height: 40px;
    width: 40px;

    @include media-breakpoint-down(sm){
        
    }
}

.initials-text {
    @include text-small1;
    text-align: center;
    margin:auto;
}