@import 'css/base.scss';

.advanced-filter-container {
    position: relative; margin: 0 -48px; padding: 24px 48px 48px; width: calc(100% + 2*48px);
    background: white; z-index: $advanced-filter-z; transition: .15s;

    @include media-breakpoint-down(sm) { margin: 0 -32px; padding: 24px 32px 32px; width: calc(100% + 2*32px); }

    &.hidden { padding: 0; width: 0; height: 0; overflow: hidden; }

    h1 { margin-bottom: pxToRem(32); }

    .accordion { margin-bottom: 0;
        .accordion-content { padding-bottom: 0; }
    }
}
