@import 'css/base.scss';

// Table view (wide screen)
.notices .table {
    thead tr {
        th { padding-left: 0; }
    }

    tbody tr { background: transparent !important;
        td { padding: pxToRem(4) 3rem pxToRem(4) 0; height: auto; vertical-align: top; 
            &:last-child { padding-right: 0; }
        }
    }
}

// List view (narrow screen)
.notices .notice-item { margin: 0 0 1rem;
    .date { font-weight: bold; }
}
