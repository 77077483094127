.options-panel {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.options-panel-item {
    display: flex;
    gap: 1rem;
}

.go-to-panel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
