.batch-file-view-as-panel {
    display: flex;
    justify-content: start;
    gap: 10px;
}

.overflow-wrap {
    min-width: 6rem;
    overflow-wrap: anywhere;
}
